/* eslint-disable import/prefer-default-export */
import { Typography } from '@mui/material';
import React, { ReactNode } from 'react';

export function FieldErrorMessage({
  children,
  marginTop,
  fontSize,
  textAlign,
}: Readonly<{
  children: ReactNode;
  marginTop?: string;
  fontSize?: string;
  textAlign?:
  | 'start'
  | 'end'
  | 'left'
  | 'right'
  | 'center'
  | 'justify'
  | 'match-parent';
}>) {
  return (
    <Typography
      color="error"
      variant="caption"
      sx={{ marginTop: marginTop || '5px', fontSize, textAlign }}
    >
      {children}
    </Typography>
  );
}
