import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  addBusinessApplicationNotes,
  BusinessApplication,
  BusinessApplicationNote,
} from '../../../Services/BusinessApplicationService';
import {
  FieldErrorMessage,
  StyledButton,
} from './shared';
import { requiredFieldErrorMessage } from '../utils';

function BusinessApplicationNotesList({ notes }: Readonly<{ notes: BusinessApplicationNote[] }>) {
  if (!notes || notes?.length === 0) {
    return (
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <Typography variant="h6">There are no notes attached to this business application</Typography>
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '10px',
        gap: '20px',
        maxHeight: '250px',
        margin: '20px 0',
        overflowY: 'auto',
      }}
    >
      {notes.map((note) => (
        <Card
          key={note.createdAt}
          style={{
            flexShrink: 0,
          }}
        >
          <CardContent>
            {' '}
            <Typography>
              <strong>{new Date(note.createdAt).toLocaleString()}</strong>
              {' '}
              -
              {' '}
              {note.userName}
            </Typography>
            <Divider style={{ margin: '10px 0' }} />
            {note.content}
          </CardContent>
        </Card>
      ))}
    </div>
  );
}

function BusinessApplicationNotes({
  businessApplication,
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  businessApplication: BusinessApplication;
  onSetBusinessApplicationToEditHandler: (
    businessApplication: BusinessApplication
  ) => void;
}>) {
  const {
    register, handleSubmit, formState, setValue,
  } = useForm<Pick<BusinessApplicationNote, 'content'>>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const onFormSubmitHandler = async (
    data: Pick<BusinessApplicationNote, 'content'>,
  ) => {
    try {
      setError(false);
      setIsLoading(true);
      const result = await addBusinessApplicationNotes(
        businessApplication.businessId,
        data.content,
      );
      onSetBusinessApplicationToEditHandler(result.data);
      setValue('content', '');
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: '50px',
        backgroundColor: 'white',
      }}
    >
      <CardContent>
        <BusinessApplicationNotesList notes={businessApplication.notes} />
        <form onSubmit={handleSubmit(onFormSubmitHandler)}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Divider style={{ marginTop: '15px', marginBottom: '25px' }} />
              <Typography variant="h4">Add new note</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register('content', {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                required
                multiline
                maxRows={5}
                minRows={1}
                defaultValue=""
                error={!!formState.errors.content}
                label="Note"
              />
              {formState.errors?.content && (
                <FieldErrorMessage>
                  {formState.errors?.content?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12} sm={12} sx={{ marginTop: '20px' }}>
              <StyledButton
                disabled={isLoading}
                fullWidth
                type="submit"
                withChildComponent={isLoading}
              >
                {isLoading ? <CircularProgress size="20px" /> : 'Save new note'}
              </StyledButton>
              {error && (
                <Typography variant="body2" color="error" textAlign="center" marginTop="15px">
                  Something went wrong while submitting the update, please try again
                </Typography>
              )}
            </Grid>

            <Grid />
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}

export default BusinessApplicationNotes;
