import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Card,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { IoMdRefresh } from 'react-icons/io';
import {
  BusinessApplication,
  BusinessApplicationBillingInfo,
  updateBusinessApplication,
} from '../../../Services/BusinessApplicationService';
import {
  CardTitle,
  CardTitleText,
  ExpandedContent,
  ExpandMore,
  FieldErrorMessage,
  StyledButton,
  StyledIconButton,
} from './shared';
import useGetAllStripeAllowedProduct from '../hooks/useGetAllStripeAllowedProducts';
import { StripeAllowedProduct, StripeAllowedProductDiscount } from '../../../Services/BillingService';

const IsLoadingStripeAllowedProduct = (
  <Box width="100%" textAlign="center">
    <CircularProgress />
  </Box>
);

const StripeAllowedProductsError = (
  <Box width="100%" textAlign="center">
    <FieldErrorMessage fontSize="1rem">
      It was not possible to fetch the stripe product. Please reach out support.
    </FieldErrorMessage>
  </Box>
);

const setValueOptions = {
  shouldValidate: true,
  shouldTouch: true,
  shouldDirty: true,
};

const getStripeProductAndMandatoryDiscount = (
  stripeAllowedProducts: StripeAllowedProduct[],
  selectedStripeProductId: string,
): { stripeProduct: StripeAllowedProduct, mandatoryDiscount: StripeAllowedProductDiscount | null } => {
  const stripeProduct = stripeAllowedProducts.find(
    (product) => product.stripeProductId === selectedStripeProductId,
  )!;
  const mandatoryDiscount = stripeProduct?.discounts?.find(
    (discount) => discount.applyOnSubscriptionCreation === true,
  ) || null;

  return {
    stripeProduct,
    mandatoryDiscount,
  };
};

function BusinessApplicationBillingDetails({
  businessApplication,
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  businessApplication: BusinessApplication;
  onSetBusinessApplicationToEditHandler: (
    businessApplication: BusinessApplication
  ) => void;
}>) {
  const [refreshData, setRefreshData] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittingError, setSubmittingError] = useState(false);
  const [showSubscriptionPriceInput, setShowSubscriptionPriceInput] = useState(false);
  const [availableDiscounts, setAvailableDiscounts] = useState<StripeAllowedProductDiscount[] | null>(null);
  const { stripeAllowedProducts, isLoading, error } = useGetAllStripeAllowedProduct(refreshData);
  const {
    register, handleSubmit, getValues, setValue, formState,
  } = useForm<BusinessApplicationBillingInfo>();

  useEffect(() => {
    if (businessApplication.billingInformation?.stripeProductId && stripeAllowedProducts?.length > 0) {
      const { stripeProduct, mandatoryDiscount } = getStripeProductAndMandatoryDiscount(
        stripeAllowedProducts,
        businessApplication.billingInformation?.stripeProductId,
      );

      setShowSubscriptionPriceInput(stripeProduct ? !stripeProduct?.isVolumeBased : false);

      if (!mandatoryDiscount) {
        setAvailableDiscounts(stripeProduct?.discounts);
      }
    }
  }, [stripeAllowedProducts?.length, businessApplication.billingInformation]);

  const onFormSubmitHandler = useCallback(async (data: BusinessApplicationBillingInfo) => {
    try {
      setSubmittingError(false);
      setIsSubmitting(true);
      const result = await updateBusinessApplication(
        {
          ...businessApplication,
          billingInformation: {
            ...data,
            useVolumeBaseBilling: Boolean(data.useVolumeBaseBilling),
          },
        },
      );
      onSetBusinessApplicationToEditHandler(result.data);
    } catch (e) {
      console.log(e);
      setSubmittingError(true);
    } finally {
      setIsSubmitting(false);
    }
  }, [businessApplication.updatedAt]);

  const onRefreshHandler = () => {
    setIsExpanded(true);
    setRefreshData((prev) => !prev);
  };

  const onProductChangeHandler = (e: any) => {
    const { stripeProduct, mandatoryDiscount } = getStripeProductAndMandatoryDiscount(
      stripeAllowedProducts,
      e.target.value,
    );

    setValue('stripeProductId', stripeProduct?.stripeProductId, setValueOptions);
    setValue('stripePriceId', stripeProduct?.stripePriceId, setValueOptions);
    setValue('billingInterval', stripeProduct?.billingInterval, setValueOptions);
    setValue('useVolumeBaseBilling', Boolean(stripeProduct?.isVolumeBased), setValueOptions);
    setValue('subscriptionStartingQuantity', stripeProduct?.subscriptionStartingQuantity.toString(), setValueOptions);
    setValue('stripeDiscountId', mandatoryDiscount?.stripeDiscountId || '', setValueOptions);

    setShowSubscriptionPriceInput(!stripeProduct?.isVolumeBased);
    setAvailableDiscounts(stripeProduct?.discounts && !mandatoryDiscount ? stripeProduct?.discounts : null);
  };

  const onDiscountChangeHandler = (e: any) => {
    const stripeProduct = stripeAllowedProducts.find(
      (product) => product.stripeProductId === getValues().stripeProductId,
    );
    const mandatoryDiscount = stripeProduct?.discounts?.find(
      (discount) => discount.applyOnSubscriptionCreation === true,
    );

    setValue('stripeDiscountId', !mandatoryDiscount ? e.target.value : '', setValueOptions);
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardTitle>
        <CardTitleText variant="h4">Billing details</CardTitleText>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <StyledIconButton
            disabled={!isExpanded}
            onClick={() => onRefreshHandler()}
            size="small"
          >
            <IoMdRefresh />
          </StyledIconButton>
          <ExpandMore
            expand={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <form onSubmit={handleSubmit(onFormSubmitHandler)}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h4">Basic information</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('taxId')}
                  fullWidth
                  defaultValue={businessApplication.billingInformation?.taxId}
                  error={!!formState.errors.taxId}
                  label="Tax ID"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('registeredBusinessName')}
                  fullWidth
                  defaultValue={
                    businessApplication.billingInformation
                      ?.registeredBusinessName
                  }
                  error={!!formState.errors.registeredBusinessName}
                  label="Registered business name"
                />
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Stripe product</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                {isLoading && IsLoadingStripeAllowedProduct}
                {error && StripeAllowedProductsError}
                {!isLoading && !error && (
                  <TextField
                    {...register('stripeProductId', {
                      onChange: onProductChangeHandler,
                    })}
                    fullWidth
                    select
                    defaultValue={
                      businessApplication.billingInformation.stripeProductId
                      || ''
                    }
                    label="Stripe product"
                  >
                    {stripeAllowedProducts.map((product) => (
                      <MenuItem
                        key={product.stripeProductId}
                        value={product.stripeProductId}
                      >
                        {product.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>

              {showSubscriptionPriceInput && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h4">
                      Stripe
                      {' '}
                      {'subscription\'s'}
                      {' '}
                      total price - one unit equals to
                      one currency unit (1 = 1
                      {' '}
                      {businessApplication.billingInformation.currency || 'eur'}
                      )
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...register('subscriptionStartingQuantity', {
                        valueAsNumber: true,
                        min: {
                          value: 1,
                          message: `You cannot have a flat price subscription with a total price of 0 ${
                            businessApplication.billingInformation.currency
                            || 'eur'
                          }`,
                        },
                      })}
                      fullWidth
                      label={`Price payed by the business per ${
                        getValues().billingInterval
                      }`}
                      type="number"
                      defaultValue={
                        getValues().subscriptionStartingQuantity
                        || businessApplication.billingInformation
                          ?.subscriptionStartingQuantity
                        || 0
                      }
                      error={!!formState.errors.subscriptionStartingQuantity}
                    />
                    {formState.errors.subscriptionStartingQuantity?.message && (
                      <FieldErrorMessage>
                        {formState.errors.subscriptionStartingQuantity?.message}
                      </FieldErrorMessage>
                    )}
                  </Grid>
                </>
              )}

              {availableDiscounts && availableDiscounts.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h4">
                      Stripe available discounts
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      onChange={onDiscountChangeHandler}
                      fullWidth
                      label="Stripe discount"
                      select
                      value={getValues().stripeDiscountId || ''}
                    >
                      {availableDiscounts.map((discount) => (
                        <MenuItem
                          key={discount.stripeDiscountId}
                          value={discount.stripeDiscountId}
                        >
                          {discount.name}
                        </MenuItem>
                      ))}
                      <MenuItem key="_" value="">
                        No discount
                      </MenuItem>
                    </TextField>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Stripe information summary</Typography>
                <Typography variant="subtitle1">
                  All information below are readonly
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('stripeCustomerId')}
                  fullWidth
                  defaultValue={
                    businessApplication.billingInformation?.stripeCustomerId
                  }
                  error={!!formState.errors.stripeCustomerId}
                  label="Stripe customer ID"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('stripeProductId')}
                  fullWidth
                  defaultValue={
                    businessApplication.billingInformation?.stripeProductId
                  }
                  error={!!formState.errors.stripeProductId}
                  label="Stripe product ID"
                  InputLabelProps={{ shrink: true, color: 'primary' }}
                  inputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('stripePriceId')}
                  fullWidth
                  defaultValue={
                    businessApplication.billingInformation?.stripePriceId
                  }
                  error={!!formState.errors.stripePriceId}
                  label="Stripe price ID"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('billingInterval')}
                  fullWidth
                  defaultValue={
                    businessApplication.billingInformation?.billingInterval
                  }
                  error={!!formState.errors.billingInterval}
                  label="Stripe price ID"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('stripeDiscountId')}
                  fullWidth
                  defaultValue={
                    businessApplication.billingInformation?.stripeDiscountId
                  }
                  error={!!formState.errors.stripeDiscountId}
                  label="Stripe discount ID"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('useVolumeBaseBilling')}
                  fullWidth
                  defaultValue={
                    businessApplication.billingInformation
                      ?.useVolumeBaseBilling !== null
                    && businessApplication.billingInformation?.stripeProductId
                      ? businessApplication.billingInformation?.useVolumeBaseBilling!.toString()
                      : ''
                  }
                  error={!!formState.errors.billingInterval}
                  label="Is volume based billing"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={12} sm={12} sx={{ marginTop: '20px' }}>
                <StyledButton
                  disabled={isSubmitting}
                  fullWidth
                  type="submit"
                  withChildComponent={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress size="20px" />
                  ) : (
                    'Save changes'
                  )}
                </StyledButton>
                {submittingError && (
                  <Typography
                    variant="body2"
                    color="error"
                    textAlign="center"
                    marginTop="15px"
                  >
                    Something went wrong while submitting the update, please try
                    again
                  </Typography>
                )}
              </Grid>
            </Grid>
          </form>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default BusinessApplicationBillingDetails;
