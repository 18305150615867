import React from 'react';
import {
  Chip,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { FaCopy, FaEdit } from 'react-icons/fa';
import { BusinessApplication } from '../../../Services/BusinessApplicationService';
import TitleComponent from '../../../Components/BasicComponents/TitleComponent';
import { getBusinessApplicationStatusChipColor, getTagOrderStatusChipColor } from '../utils';
import useFindAllBusinessApplications from '../hooks/useFindAllBusinessApplications';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  border: '1px solid',
  maxHeight: '510px',
  [theme.breakpoints.down('sm')]: {
    maxHeight: '400px',
  },
}));

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  fontWeight: 700,
  textAlign: 'center',
}));

const StyledBodyCell = styled(
  TableCell,
  {},
)({
  textAlign: 'center',
  fontWeight: 'inherit',
  padding: '10px',
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.main,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

function UserIdBodyCell({ userId }: Readonly<{ userId: string }>) {
  return (
    <div style={{ display: 'flex', gap: '5px', justifyContent: 'center' }}>
      <span
        style={{
          maxWidth: '100px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          display: 'inline-block',
        }}
      >
        {userId}
      </span>
      <StyledIconButton
        onClick={() => navigator.clipboard.writeText(userId)}
        size="small"
      >
        <FaCopy />
      </StyledIconButton>
    </div>
  );
}

const loadingState = (
  <TableRow>
    <TableCell colSpan={11} align="center" sx={{ padding: '150px' }}><CircularProgress /></TableCell>
  </TableRow>
);

const errorState = (
  <TableRow>
    <TableCell colSpan={9} align="center" sx={{ padding: '150px' }}>
      <Typography color="error">
        Something went wrong while fetching the business applications, please try to reload the page.
      </Typography>
    </TableCell>
  </TableRow>
);

function BusinessApplicationsTable({
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  onSetBusinessApplicationToEditHandler: (businessApplication: BusinessApplication) => void;
}>) {
  const { businessApplications, isLoading, error } = useFindAllBusinessApplications();

  return (
    <>
      <TitleComponent text="Business Applications" />
      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledHeaderCell>Business Id</StyledHeaderCell>
              <StyledHeaderCell>Business name</StyledHeaderCell>
              <StyledHeaderCell>Status</StyledHeaderCell>
              <StyledHeaderCell>Tag order status</StyledHeaderCell>
              <StyledHeaderCell>Sales person</StyledHeaderCell>
              <StyledHeaderCell>Business User Id</StyledHeaderCell>
              <StyledHeaderCell>Locations</StyledHeaderCell>
              <StyledHeaderCell>Schemes</StyledHeaderCell>
              <StyledHeaderCell>Created At</StyledHeaderCell>
              <StyledHeaderCell>Updated At</StyledHeaderCell>
              <StyledHeaderCell>Edit</StyledHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && loadingState}
            {error && errorState}
            {!isLoading && !error && businessApplications!.map((businessApplication) => (
              <TableRow key={businessApplication.businessId}>
                <StyledBodyCell>{businessApplication.businessId}</StyledBodyCell>
                <StyledBodyCell>
                  <span style={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                  }}
                  >
                    {businessApplication.businessName}
                  </span>
                </StyledBodyCell>
                <StyledBodyCell>
                  <Chip
                    sx={{ width: '185px' }}
                    label={businessApplication.applicationStatus}
                    color={getBusinessApplicationStatusChipColor(
                      businessApplication.applicationStatus,
                    )}
                  />
                </StyledBodyCell>
                <StyledBodyCell>
                  <Chip
                    sx={{ width: '185px' }}
                    label={businessApplication.tagOrderStatus}
                    color={getTagOrderStatusChipColor(
                      businessApplication.tagOrderStatus,
                    )}
                  />
                </StyledBodyCell>
                <StyledBodyCell>
                  {(businessApplication.salesPerson || '').split('@')[0]}
                </StyledBodyCell>
                <StyledBodyCell>
                  <UserIdBodyCell userId={businessApplication.businessUserId} />
                </StyledBodyCell>

                <StyledBodyCell>
                  {businessApplication.locations?.length || 0}
                </StyledBodyCell>
                <StyledBodyCell>
                  {businessApplication.schemes?.length || 0}
                </StyledBodyCell>
                <StyledBodyCell>
                  {new Date(businessApplication.createdAt).toLocaleDateString()}
                </StyledBodyCell>
                <StyledBodyCell>
                  {businessApplication.updatedAt
                    ? new Date(businessApplication.updatedAt).toLocaleDateString()
                    : ''}
                </StyledBodyCell>
                <StyledBodyCell>
                  <StyledIconButton
                    onClick={() => onSetBusinessApplicationToEditHandler(businessApplication)}
                    size="small"
                  >
                    <FaEdit />
                  </StyledIconButton>
                </StyledBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
}

export default BusinessApplicationsTable;
