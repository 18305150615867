import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Card,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import {
  BusinessApplication,
  BusinessApplicationTagOrderStatus,
  updateBusinessApplication,
} from '../../../Services/BusinessApplicationService';
import {
  CardTitle,
  CardTitleText,
  ExpandedContent,
  ExpandMore,
  FieldErrorMessage,
  StyledButton,
} from './shared';
import { requiredFieldErrorMessage } from '../utils';

type BusinessApplicationTagData = Pick<
BusinessApplication,
| 'tagOrderStatus'
| 'tagDeliveryAddress'
| 'tagDeliveryToEachLocation'
| 'tagDeliveryTrackingNumber'
>;

function BusinessApplicationTagOrderDetails({
  businessApplication,
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  businessApplication: BusinessApplication;
  onSetBusinessApplicationToEditHandler: (
    businessApplication: BusinessApplication
  ) => void;
}>) {
  const {
    register, handleSubmit, getValues, formState,
  } = useForm<BusinessApplicationTagData>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const onFormSubmitHandler = useCallback(async (data: BusinessApplicationTagData) => {
    try {
      setError(false);
      setIsLoading(true);
      const result = await updateBusinessApplication(
        { ...businessApplication, ...data, tagDeliveryToEachLocation: Boolean(data.tagDeliveryToEachLocation) },
      );
      onSetBusinessApplicationToEditHandler(result.data);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, [businessApplication.updatedAt]);

  return (
    <Card sx={{ width: '100%' }}>
      <CardTitle>
        <CardTitleText variant="h4">Tag details</CardTitleText>
        <ExpandMore
          expand={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
          aria-expanded={isExpanded}
          aria-label="show more"
        >
          <MdExpandMore />
        </ExpandMore>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <form onSubmit={handleSubmit(onFormSubmitHandler)}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h4">Tag order status</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  {...register('tagOrderStatus', {
                    required: requiredFieldErrorMessage,
                  })}
                  fullWidth
                  select
                  disabled={
                    businessApplication.tagOrderStatus
                    === BusinessApplicationTagOrderStatus.DELIVERED
                  }
                  defaultValue={businessApplication.tagOrderStatus}
                  error={!!formState.errors.tagOrderStatus}
                  label="Tag order status"
                >
                  <MenuItem
                    value={BusinessApplicationTagOrderStatus.NOT_PURCHASED}
                  >
                    NOT PURCHASED
                  </MenuItem>
                  <MenuItem value={BusinessApplicationTagOrderStatus.PURCHASED}>
                    PURCHASED
                  </MenuItem>
                  <MenuItem value={BusinessApplicationTagOrderStatus.POSTED}>
                    POSTED
                  </MenuItem>
                  <MenuItem
                    value={BusinessApplicationTagOrderStatus.IN_TRANSIT}
                  >
                    IN TRANSIT
                  </MenuItem>
                  <MenuItem
                    value={BusinessApplicationTagOrderStatus.OUT_FOR_DELIVERY}
                  >
                    OUT FOR DELIVERY
                  </MenuItem>
                  <MenuItem value={BusinessApplicationTagOrderStatus.DELIVERED}>
                    DELIVERED
                  </MenuItem>
                </TextField>
                {formState.errors?.tagOrderStatus && (
                  <FieldErrorMessage>
                    {formState.errors?.tagOrderStatus?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Shipping details</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('tagDeliveryAddress')}
                  fullWidth
                  defaultValue={businessApplication.tagDeliveryAddress}
                  error={!!formState.errors.tagDeliveryAddress}
                  label="Tag delivery address"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('tagDeliveryTrackingNumber')}
                  fullWidth
                  defaultValue={businessApplication.tagDeliveryTrackingNumber}
                  error={!!formState.errors.tagDeliveryTrackingNumber}
                  label="Tag delivery tracking number"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  {...register('tagDeliveryToEachLocation', {
                    validate: (value: BusinessApplication['tagDeliveryToEachLocation']) => {
                      if (!getValues().tagDeliveryAddress) {
                        return true;
                      }

                      return getValues().tagDeliveryAddress && value
                        ? 'You cannot delivery tags to each location if a tag delivery address is specified'
                        : true;
                    },
                  })}
                  fullWidth
                  select
                  defaultValue={
                    businessApplication.tagDeliveryToEachLocation ? 1 : 0
                  }
                  error={!!formState.errors.tagDeliveryToEachLocation}
                  label="Deliver tag to each location address"
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>
                {formState.errors?.tagDeliveryToEachLocation && (
                  <FieldErrorMessage>
                    {
                      formState.errors?.tagDeliveryToEachLocation
                        ?.message as string
                    }
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={12} sx={{ marginTop: '20px' }}>
                <StyledButton
                  disabled={isLoading}
                  fullWidth
                  type="submit"
                  withChildComponent={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size="20px" />
                  ) : (
                    'Save changes'
                  )}
                </StyledButton>
                {error && (
                  <Typography variant="body2" color="error" textAlign="center" marginTop="15px">
                    Something went wrong while submitting the update, please try again
                  </Typography>
                )}
              </Grid>
            </Grid>
          </form>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default BusinessApplicationTagOrderDetails;
