import React, { useCallback, useState } from 'react';
import Content from '../../Components/BasicComponents/Content';
import { BusinessApplication } from '../../Services/BusinessApplicationService';
import BusinessApplicationsTable from './components/BusinessApplicationsTable';
import BusinessApplicationDetails from './components/BusinessApplicationDetails';

function BusinessApplicationsPage() {
  const [businessApplicationToEdit, setBusinessApplicationToEdit] = useState<BusinessApplication | null>(null);

  const onSetBusinessApplicationToEditHandler = useCallback(
    (businessApplication: BusinessApplication) => {
      setBusinessApplicationToEdit((prev) => ({
        ...prev,
        ...businessApplication,
      }));
    },
    [businessApplicationToEdit?.updatedAt],
  );

  return (
    <Content>
      {!businessApplicationToEdit && (
        <BusinessApplicationsTable
          onSetBusinessApplicationToEditHandler={onSetBusinessApplicationToEditHandler}
        />
      )}

      {businessApplicationToEdit && (
        <BusinessApplicationDetails
          businessApplication={businessApplicationToEdit}
          onBackButtonHandler={() => setBusinessApplicationToEdit(null)}
          onSetBusinessApplicationToEditHandler={onSetBusinessApplicationToEditHandler}
        />
      )}
    </Content>
  );
}

export default BusinessApplicationsPage;
