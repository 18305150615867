import { useEffect, useState } from 'react';
import { BusinessApplication, findAllBusinessApplications } from '../../../Services/BusinessApplicationService';

const useFindAllBusinessApplications = () => {
  const [businessApplications, setBusinessApplications] = useState<
  BusinessApplication[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      try {
        setError(false);
        setIsLoading(true);
        const result = await findAllBusinessApplications(
          abortController,
        );
        setBusinessApplications(result.data || []);
      } catch (e: any) {
        if (e.name !== 'AbortError') {
          setError(true);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => abortController?.abort();
  }, []);

  return {
    businessApplications,
    isLoading,
    error,
  };
};

export default useFindAllBusinessApplications;
