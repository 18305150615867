/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const StyledImage = styled('img')<Readonly<{
  width?: string;
  height?: string;
  fullWidth?: boolean;
}>>(({
  theme, width, height, fullWidth,
}) => ({
  width: width || (fullWidth ? '100%' : '300px'),
  height: height || (fullWidth ? '100%' : '300px'),
  objectFit: 'scale-down',
  boxShadow: '10px 10px 10px rgba(0,0,0,0.6)',
  marginBottom: '15px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    boxShadow: '5px 5px 5px rgba(0,0,0,0.4)',
  },
}));

export function SingleImagePreview({
  image,
  noImageChild,
}: Readonly<{
  image?: string | null;
  noImageChild: string | React.ReactNode;
}>) {
  return image ? (
    <StyledImage src={image} />
  ) : (
    <div>
      {typeof noImageChild === 'string' ? (
        <Typography align="center" padding="100px">
          {noImageChild}
        </Typography>
      ) : (
        noImageChild
      )}
    </div>
  );
}

export function MultiImagesPreview({
  images,
  noImageChild,
}: Readonly<{
  images?: string[] | null;
  noImageChild: string | React.ReactNode;
}>) {
  return images && images.length > 0 ? (
    <div style={{ overflowX: 'auto', display: 'flex', gap: '15px' }}>
      {images.map((imgSrc) => (
        <StyledImage key={imgSrc} src={imgSrc} />
      ))}
    </div>
  ) : (
    <div>
      {typeof noImageChild === 'string' ? (
        <Typography align="center" padding="50px">
          {noImageChild}
        </Typography>
      ) : (
        noImageChild
      )}
    </div>
  );
}
