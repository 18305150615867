import React, { useState } from 'react';
import {
  Card, CircularProgress, Collapse, Grid, Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { IoMdRefresh } from 'react-icons/io';
import {
  BusinessApplication,
  BusinessApplicationAuditLog,
  revertBusinessApplication,
} from '../../../Services/BusinessApplicationService';
import {
  CardTitle,
  CardTitleText,
  ExpandedContent,
  ExpandMore,
  StyledButton,
  StyledIconButton,
} from './shared';
import useGetBusinessApplicationAuditLogs from '../hooks/useGetBusinessApplicationAuditLogs';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';

function BusinessApplicationAuditLogCard({
  auditLog,
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  auditLog: BusinessApplicationAuditLog,
  onSetBusinessApplicationToEditHandler: (businessApplication: BusinessApplication) => void
}>) {
  const [revert, setRevert] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const revertHandler = async () => {
    try {
      setIsLoading(true);
      const result = await revertBusinessApplication(auditLog.businessId, auditLog.id);

      onSetBusinessApplicationToEditHandler(result.data);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setRevert(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Card sx={{
        width: '100%',
        '&:not(:first-of-type)': {
          marginTop: '15px',
        },
      }}
      >
        <CardTitle>
          <CardTitleText variant="h4">
            {`${auditLog.operation} - ${new Date(auditLog.createdAt).toLocaleString()}`}
          </CardTitleText>
          <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            <Typography color="white" variant="h4">
              USER
              {' - '}
              {auditLog.createdBy}
            </Typography>
            <ExpandMore
              expand={isExpanded}
              onClick={() => setIsExpanded(!isExpanded)}
              aria-expanded={isExpanded}
              aria-label="show more"
            >
              <MdExpandMore />
            </ExpandMore>
          </div>
        </CardTitle>
        <Collapse in={isExpanded}>
          <ExpandedContent>
            <Grid container gap={2} alignItems="center">
              <Grid item xs={12}>
                <StyledButton
                  fullWidth
                  disabled={isLoading}
                  withChildComponent={isLoading}
                  onClick={() => setRevert(true)}
                >
                  {isLoading ? <CircularProgress size="20px" /> : 'Revert to the previous state of this log'}
                </StyledButton>
              </Grid>

              <Grid item xs={5}>
                <Typography variant="h4">Previous state</Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography variant="h4">Current state</Typography>
              </Grid>

              <Grid item xs={5} alignSelf="flex-start">
                <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflow: 'hidden' }}>
                  <code>{JSON.stringify(auditLog.previousState, null, 2)}</code>
                </pre>
              </Grid>

              <Grid item xs={5} alignSelf="flex-start">
                <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflow: 'hidden' }}>
                  <code>{JSON.stringify(auditLog.currentState, null, 2)}</code>
                </pre>
              </Grid>
            </Grid>
          </ExpandedContent>
        </Collapse>
      </Card>
      <ErrorDialog
        open={error}
        dialogTitle="Something went wrong"
        dialogText="It was not possible to revert the business application state, please try again."
        handleClose={() => setError(false)}
      />
      <ConfirmDialog
        dialogTitle="Confirm your action"
        dialogText="Are you sure you want to revert the state of this business application?"
        open={revert}
        isLoading={isLoading}
        handleCancel={() => setRevert(false)}
        handleConfirm={() => revertHandler()}
      />
    </>
  );
}

const loadingState = (
  <div
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: '150px 0',
    }}
  >
    <CircularProgress />
  </div>
);

const errorState = (
  <div style={{
    padding: '150px 0',
  }}
  >
    <Typography color="error" align="center">
      Something went wrong while trying to fetch the business application audit logs, please try again.
    </Typography>
  </div>
);

function BusinessApplicationAuditLogs({
  businessApplication,
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  businessApplication: BusinessApplication;
  onSetBusinessApplicationToEditHandler: (businessApplication: BusinessApplication) => void
}>) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  const { businessApplicationAuditLogs, isLoading, error } = useGetBusinessApplicationAuditLogs(
    businessApplication.businessId,
    isExpanded,
    refreshData,
  );

  const onRefreshHandler = () => {
    setIsExpanded(true);
    setRefreshData((prev) => !prev);
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardTitle>
        <CardTitleText variant="h4">Audit Logs</CardTitleText>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <StyledIconButton
            disabled={!isExpanded}
            onClick={() => onRefreshHandler()}
            size="small"
          >
            <IoMdRefresh />
          </StyledIconButton>
          <ExpandMore
            expand={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          {isLoading && loadingState}
          {error && errorState}
          {!isLoading && !error && businessApplicationAuditLogs.map((auditLog) => (
            <BusinessApplicationAuditLogCard
              key={auditLog.id}
              auditLog={auditLog}
              onSetBusinessApplicationToEditHandler={onSetBusinessApplicationToEditHandler}
            />
          ))}
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default BusinessApplicationAuditLogs;
