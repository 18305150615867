import React, { useCallback, useState } from 'react';
import {
  Card, CircularProgress, Collapse, Divider, Grid, TextField, Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import {
  BusinessApplication,
  BusinessProfile,
  updateBusinessApplication,
} from '../../../Services/BusinessApplicationService';
import {
  CardTitle,
  CardTitleText,
  ExpandedContent,
  ExpandMore,
  BusinessApplicationImageUploadButton,
  MultiImagesPreview,
  SingleImagePreview,
  StyledButton,
} from './shared';

function BusinessApplicationBusinessProfile({
  businessApplication,
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  businessApplication: BusinessApplication;
  onSetBusinessApplicationToEditHandler: (
    businessApplication: BusinessApplication
  ) => void;
}>) {
  const {
    register, handleSubmit, formState,
  } = useForm<BusinessApplication['businessProfile']>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const onFormSubmitHandler = useCallback(async (data: BusinessProfile) => {
    try {
      setError(false);
      setIsLoading(true);
      const result = await updateBusinessApplication(
        { ...businessApplication, businessProfile: { ...businessApplication.businessProfile, ...data } },
      );
      onSetBusinessApplicationToEditHandler(result.data);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <Card sx={{ width: '100%' }}>
      <CardTitle>
        <CardTitleText variant="h4">Business profile</CardTitleText>
        <ExpandMore
          expand={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
          aria-expanded={isExpanded}
          aria-label="show more"
        >
          <MdExpandMore />
        </ExpandMore>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <form onSubmit={handleSubmit(onFormSubmitHandler)}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h4">General info</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  {...register('description', { setValueAs: (v) => v || null })}
                  fullWidth
                  multiline
                  minRows={5}
                  maxRows={5}
                  defaultValue={businessApplication.tagDeliveryAddress}
                  error={!!formState.errors.description}
                  label="Business description"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Cover images</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Logo images</Typography>
              </Grid>

              <Grid container item xs={12} sm={6} justifyContent="center">
                <SingleImagePreview
                  image={businessApplication.businessProfile.coverImage}
                  noImageChild="No cover image"
                />
              </Grid>

              <Grid container item xs={12} sm={6} justifyContent="center">
                <SingleImagePreview
                  image={businessApplication.businessProfile.logoImage}
                  noImageChild="No logo image"
                />
              </Grid>

              <Grid container item xs={12} sm={6} justifyContent="center">
                <BusinessApplicationImageUploadButton
                  buttonLabel="Upload new cover"
                  businessApplication={businessApplication}
                  folder="cover"
                  onSuccessfulUploadHandler={onSetBusinessApplicationToEditHandler}
                />
              </Grid>

              <Grid container item xs={12} sm={6} justifyContent="center">
                <BusinessApplicationImageUploadButton
                  buttonLabel="Upload new logo"
                  businessApplication={businessApplication}
                  folder="logo"
                  onSuccessfulUploadHandler={onSetBusinessApplicationToEditHandler}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Our Story</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  {...register('ourStoryDescription', { setValueAs: (v) => v || null })}
                  fullWidth
                  multiline
                  minRows={5}
                  maxRows={5}
                  defaultValue={businessApplication.businessProfile?.ourStoryDescription}
                  error={!!formState.errors.ourStoryDescription}
                  label="Our story description"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h4">Our Story images</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <MultiImagesPreview
                  images={businessApplication.businessProfile.ourStoryImages}
                  noImageChild="No Story images"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <BusinessApplicationImageUploadButton
                  buttonLabel="Upload new Story images"
                  businessApplication={businessApplication}
                  folder="story"
                  multipleFilesUpload
                  buttonFullWidth
                  onSuccessfulUploadHandler={onSetBusinessApplicationToEditHandler}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Our Space</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  {...register('ourSpaceDescription', { setValueAs: (v) => v || null })}
                  fullWidth
                  multiline
                  minRows={5}
                  maxRows={5}
                  defaultValue={businessApplication.businessProfile?.ourSpaceDescription}
                  error={!!formState.errors.ourSpaceDescription}
                  label="Our space description"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h4">Our Space images</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <MultiImagesPreview
                  images={businessApplication.businessProfile.ourSpaceImages}
                  noImageChild="No Space images"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <BusinessApplicationImageUploadButton
                  buttonLabel="Upload new Space images"
                  businessApplication={businessApplication}
                  folder="space"
                  multipleFilesUpload
                  buttonFullWidth
                  onSuccessfulUploadHandler={onSetBusinessApplicationToEditHandler}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Our Ethos</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  {...register('ourEthosDescription', { setValueAs: (v) => v || null })}
                  fullWidth
                  multiline
                  minRows={5}
                  maxRows={5}
                  defaultValue={businessApplication.businessProfile?.ourEthosDescription}
                  error={!!formState.errors.ourEthosDescription}
                  label="Our ethos description"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h4">Our Ethos images</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <MultiImagesPreview
                  images={businessApplication.businessProfile.ourEthosImages}
                  noImageChild="No Ethos images"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <BusinessApplicationImageUploadButton
                  buttonLabel="Upload new Ethos images"
                  businessApplication={businessApplication}
                  folder="ethos"
                  multipleFilesUpload
                  buttonFullWidth
                  onSuccessfulUploadHandler={onSetBusinessApplicationToEditHandler}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Our Team</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  {...register('ourTeamDescription', { setValueAs: (v) => v || null })}
                  fullWidth
                  multiline
                  minRows={5}
                  maxRows={5}
                  defaultValue={businessApplication.businessProfile?.ourTeamDescription}
                  error={!!formState.errors.ourTeamDescription}
                  label="Our team description"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h4">Our Team images</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <MultiImagesPreview
                  images={businessApplication.businessProfile.ourTeamImages}
                  noImageChild="No Team images"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <BusinessApplicationImageUploadButton
                  buttonLabel="Upload new Team images"
                  businessApplication={businessApplication}
                  folder="team"
                  multipleFilesUpload
                  buttonFullWidth
                  onSuccessfulUploadHandler={onSetBusinessApplicationToEditHandler}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Our Food</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  {...register('ourTeamDescription', { setValueAs: (v) => v || null })}
                  fullWidth
                  multiline
                  minRows={5}
                  maxRows={5}
                  defaultValue={businessApplication.businessProfile?.ourFoodDescription}
                  error={!!formState.errors.ourFoodDescription}
                  label="Our food description"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('ourFoodLinks.menuLink', { setValueAs: (v) => v || null })}
                  fullWidth
                  multiline
                  defaultValue={businessApplication.businessProfile?.ourFoodLinks?.menuLink}
                  error={!!formState.errors.ourFoodLinks?.menuLink}
                  label="Menu link"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('ourFoodLinks.orderLink', { setValueAs: (v) => v || null })}
                  fullWidth
                  multiline
                  defaultValue={businessApplication.businessProfile?.ourFoodLinks?.orderLink}
                  error={!!formState.errors.ourFoodLinks?.orderLink}
                  label="Order link"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h4">Our Food images</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <MultiImagesPreview
                  images={businessApplication.businessProfile.ourFoodImages}
                  noImageChild="No Food images"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <BusinessApplicationImageUploadButton
                  buttonLabel="Upload new Food images"
                  businessApplication={businessApplication}
                  folder="food"
                  multipleFilesUpload
                  buttonFullWidth
                  onSuccessfulUploadHandler={onSetBusinessApplicationToEditHandler}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Social links</Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  {...register('socialLinks.website', { setValueAs: (v) => v || null })}
                  fullWidth
                  multiline
                  defaultValue={businessApplication.businessProfile?.socialLinks?.website}
                  error={!!formState.errors.socialLinks?.website}
                  label="Website"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('socialLinks.instagram', { setValueAs: (v) => v || null })}
                  fullWidth
                  multiline
                  defaultValue={businessApplication.businessProfile?.socialLinks?.instagram}
                  error={!!formState.errors.socialLinks?.instagram}
                  label="Instagram"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('socialLinks.meta', { setValueAs: (v) => v || null })}
                  fullWidth
                  multiline
                  defaultValue={businessApplication.businessProfile?.socialLinks?.meta}
                  error={!!formState.errors.socialLinks?.meta}
                  label="Meta"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('socialLinks.twitter', { setValueAs: (v) => v || null })}
                  fullWidth
                  multiline
                  defaultValue={businessApplication.businessProfile?.socialLinks?.twitter}
                  error={!!formState.errors.socialLinks?.twitter}
                  label="Twitter"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('socialLinks.tiktok', { setValueAs: (v) => v || null })}
                  fullWidth
                  multiline
                  defaultValue={businessApplication.businessProfile?.socialLinks?.tiktok}
                  error={!!formState.errors.socialLinks?.tiktok}
                  label="TikTok"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('socialLinks.youtube', { setValueAs: (v) => v || null })}
                  fullWidth
                  multiline
                  defaultValue={businessApplication.businessProfile?.socialLinks?.youtube}
                  error={!!formState.errors.socialLinks?.youtube}
                  label="Youtube"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('socialLinks.linkedin', { setValueAs: (v) => v || null })}
                  fullWidth
                  multiline
                  defaultValue={businessApplication.businessProfile?.socialLinks?.linkedin}
                  error={!!formState.errors.socialLinks?.linkedin}
                  label="Linkedin"
                />
              </Grid>

              <Grid item xs={12} sm={12} sx={{ marginTop: '20px' }}>
                <StyledButton
                  disabled={isLoading}
                  fullWidth
                  type="submit"
                  withChildComponent={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size="20px" />
                  ) : (
                    'Save changes'
                  )}
                </StyledButton>
                {error && (
                  <Typography variant="body2" color="error" textAlign="center" marginTop="15px">
                    Something went wrong while submitting the update, please try again
                  </Typography>
                )}
              </Grid>
            </Grid>
          </form>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default BusinessApplicationBusinessProfile;
