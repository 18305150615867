export type StripeAllowedProductDiscount = {
  stripeDiscountId: string;
  name: string;
  type: 'percentage' | 'fixed';
  duration: 'once';
  stripeProducts: string[];
  applyOnSubscriptionCreation: boolean;
};

export type StripeAllowedProduct = {
  stripeProductId: string;
  stripePriceId: string;
  billingInterval: 'year' | 'month';
  name: string;
  description: string;
  discounts: StripeAllowedProductDiscount[] | null;
  visibilityLevel: 'business' | 'internal';
  subscriptionStartingQuantity: number;
  isVolumeBased: boolean;
};

type GetAllStripeAllowedProductResponse = {
  data: StripeAllowedProduct[]
};

export const getAllStripeAllowedProduct = async (
  abortController?: AbortController,
): Promise<GetAllStripeAllowedProductResponse> => {
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/billing/v1/stripe/products`,
    {
      method: 'GET',
      signal: abortController?.signal,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return (await result.json()) as GetAllStripeAllowedProductResponse;
};
