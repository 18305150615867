import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Card,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import {
  BusinessApplication,
  BusinessApplicationPosIntegration,
  BusinessApplicationStatus,
  updateBusinessApplication,
} from '../../../Services/BusinessApplicationService';
import {
  CardTitle,
  CardTitleText,
  ExpandedContent,
  ExpandMore,
  FieldErrorMessage,
  StyledButton,
} from './shared';
import GoogleMapContainer from '../../../Components/BasicComponents/GoogleMapContainer';
import { requiredFieldErrorMessage } from '../utils';

type BusinessApplicationGeneralDetailsData = Pick<
BusinessApplication,
| 'applicationStatus'
| 'businessId'
| 'businessName'
| 'businessUserId'
| 'email'
| 'contact'
| 'phoneNumber'
| 'address'
| 'shortAddress'
| 'postalCode'
| 'city'
| 'country'
| 'latitude'
| 'longitude'
| 'googlePlaceId'
| 'posIntegration'
>;

function BusinessApplicationGeneralDetails({
  businessApplication,
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  businessApplication: BusinessApplication;
  onSetBusinessApplicationToEditHandler: (
    businessApplication: BusinessApplication
  ) => void;
}>) {
  const { register, handleSubmit, formState } = useForm<BusinessApplicationGeneralDetailsData>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const onFormSubmitHandler = async (
    data: BusinessApplicationGeneralDetailsData,
  ) => {
    // eslint-disable-next-line no-debugger
    debugger;
    try {
      setError(false);
      setIsLoading(true);
      const result = await updateBusinessApplication(
        {
          ...businessApplication,
          ...data,
          posIntegration: data?.posIntegration || null,
        },
      );
      onSetBusinessApplicationToEditHandler(result.data);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardTitle>
        <CardTitleText variant="h4">General details</CardTitleText>
        <ExpandMore
          expand={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
          aria-expanded={isExpanded}
          aria-label="show more"
        >
          <MdExpandMore />
        </ExpandMore>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <form onSubmit={handleSubmit(onFormSubmitHandler)}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h4">Application status</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  {...register('applicationStatus', {
                    required: requiredFieldErrorMessage,
                  })}
                  required
                  fullWidth
                  select
                  disabled={
                    businessApplication.applicationStatus
                    === BusinessApplicationStatus.LIVE_ON_SQUID
                  }
                  defaultValue={businessApplication.applicationStatus}
                  error={!!formState.errors.applicationStatus}
                  label="Application status"
                >
                  <MenuItem
                    value={BusinessApplicationStatus.APPLICATION_STARTED}
                  >
                    APPLICATION STARTED
                  </MenuItem>
                  <MenuItem value={BusinessApplicationStatus.PENDING_APPROVAL}>
                    PENDING APPROVAL
                  </MenuItem>
                  <MenuItem value={BusinessApplicationStatus.APPROVED}>
                    APPROVED
                  </MenuItem>
                  <MenuItem value={BusinessApplicationStatus.CHANGES_REQUIRED}>
                    CHANGES REQUIRED
                  </MenuItem>
                </TextField>
                {formState.errors?.applicationStatus && (
                  <FieldErrorMessage>
                    {formState.errors?.applicationStatus?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Basic information</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('businessName', {
                    required: requiredFieldErrorMessage,
                  })}
                  required
                  fullWidth
                  defaultValue={businessApplication.businessName}
                  error={!!formState.errors.businessName}
                  label="Business Name"
                />
                {formState.errors?.businessName && (
                  <FieldErrorMessage>
                    {formState.errors?.businessName?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('businessUserId', {
                    required: requiredFieldErrorMessage,
                  })}
                  required
                  fullWidth
                  defaultValue={businessApplication.businessUserId}
                  error={!!formState.errors.businessUserId}
                  label="User ID"
                />
                {formState.errors?.businessUserId && (
                  <FieldErrorMessage>
                    {formState.errors?.businessUserId?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('email', { required: requiredFieldErrorMessage })}
                  fullWidth
                  required
                  defaultValue={businessApplication.email}
                  error={!!formState.errors.email}
                  label="Email"
                />
                {formState.errors?.email && (
                  <FieldErrorMessage>
                    {formState.errors?.email?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('contact', {
                    required: requiredFieldErrorMessage,
                  })}
                  fullWidth
                  required
                  defaultValue={businessApplication.contact}
                  error={!!formState.errors.contact}
                  label="Contact"
                />
                {formState.errors?.contact && (
                  <FieldErrorMessage>
                    {formState.errors?.contact?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('phoneNumber', {
                    required: requiredFieldErrorMessage,
                  })}
                  fullWidth
                  required
                  defaultValue={businessApplication.phoneNumber}
                  error={!!formState.errors.phoneNumber}
                  label="Phone number"
                />
                {formState.errors?.phoneNumber && (
                  <FieldErrorMessage>
                    {formState.errors?.phoneNumber?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Address details</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('address', {
                    required: requiredFieldErrorMessage,
                  })}
                  fullWidth
                  required
                  defaultValue={businessApplication.address}
                  error={!!formState.errors.address}
                  label="Address"
                />
                {formState.errors?.address && (
                  <FieldErrorMessage>
                    {formState.errors?.address?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('shortAddress', {
                    required: requiredFieldErrorMessage,
                  })}
                  fullWidth
                  required
                  defaultValue={businessApplication.shortAddress}
                  error={!!formState.errors.shortAddress}
                  label="Short address"
                />
                {formState.errors?.shortAddress && (
                  <FieldErrorMessage>
                    {formState.errors?.shortAddress?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('postalCode', {
                    required: requiredFieldErrorMessage,
                  })}
                  fullWidth
                  required
                  defaultValue={businessApplication.postalCode}
                  error={!!formState.errors.postalCode}
                  label="Postal code"
                />
                {formState.errors?.postalCode && (
                  <FieldErrorMessage>
                    {formState.errors?.postalCode?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('city', { required: requiredFieldErrorMessage })}
                  fullWidth
                  required
                  defaultValue={businessApplication.city}
                  error={!!formState.errors.city}
                  label="City"
                />
                {formState.errors?.city && (
                  <FieldErrorMessage>
                    {formState.errors?.city?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('country', {
                    required: requiredFieldErrorMessage,
                  })}
                  fullWidth
                  required
                  defaultValue={businessApplication.country}
                  error={!!formState.errors.country}
                  label="Country"
                />
                {formState.errors?.country && (
                  <FieldErrorMessage>
                    {formState.errors?.country?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('googlePlaceId', {
                    required: requiredFieldErrorMessage,
                  })}
                  fullWidth
                  required
                  defaultValue={businessApplication.googlePlaceId || 0}
                  error={!!formState.errors.googlePlaceId}
                  label="Google place ID"
                />
                {formState.errors?.googlePlaceId && (
                  <FieldErrorMessage>
                    {formState.errors?.googlePlaceId?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('latitude', {
                    required: requiredFieldErrorMessage,
                    valueAsNumber: true,
                  })}
                  fullWidth
                  required
                  defaultValue={businessApplication.latitude || 0}
                  error={!!formState.errors.latitude}
                  label="Latitude"
                />
                {formState.errors?.latitude && (
                  <FieldErrorMessage>
                    {formState.errors?.latitude?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('longitude', {
                    required: requiredFieldErrorMessage,
                    valueAsNumber: true,
                  })}
                  fullWidth
                  required
                  defaultValue={businessApplication.longitude || 0}
                  error={!!formState.errors.longitude}
                  label="Longitude"
                />
                {formState.errors?.longitude && (
                  <FieldErrorMessage>
                    {formState.errors?.longitude?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12}>
                <GoogleMapContainer
                  lat={businessApplication.latitude}
                  lng={businessApplication.longitude}
                  zoom={17}
                  enableMapOptions={false}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Integrations</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  {...register('posIntegration')}
                  fullWidth
                  select
                  defaultValue={businessApplication.posIntegration || ''}
                  label="POS Integration"
                >
                  <MenuItem value={BusinessApplicationPosIntegration.SQUARE}>
                    Square
                  </MenuItem>
                  <MenuItem value="">NO POS INTEGRATIONS</MenuItem>
                </TextField>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                sx={{ marginTop: '20px', textAlign: 'center' }}
              >
                <StyledButton
                  disabled={isLoading}
                  fullWidth
                  type="submit"
                  withChildComponent={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size="20px" />
                  ) : (
                    'Save changes'
                  )}
                </StyledButton>
                {error && (
                  <Typography variant="body2" color="error" textAlign="center" marginTop="15px">
                    Something went wrong while submitting the update, please try again
                  </Typography>
                )}
              </Grid>
            </Grid>
          </form>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default BusinessApplicationGeneralDetails;
