/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import {
  Button, CircularProgress, Grid, SxProps, Theme, Typography,
} from '@mui/material';
import { Box, styled } from '@mui/system';
import { BsCloudUpload } from 'react-icons/bs';
import {
  AllowedFileFolder,
  BusinessApplication,
  uploadBusinessApplicationFiles,
} from '../../../../Services/BusinessApplicationService';
import ConfirmDialog from '../../../../Components/BasicComponents/ConfirmDialog';
import { StyledImage } from './StyledImage';

const UploadButton = styled(Button)<Readonly<{ width?: string, height?: string }>>(({ theme, width, height }) => ({
  color: theme.palette.common.white,
  width,
  height,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
})) as typeof Button;

export function StyledUploadButton({
  buttonLabel,
  onFilesChangeHandler,
  disabled,
  multipleFileUpload,
  fullWidth,
  startIcon,
  sx,
}: Readonly<{
  buttonLabel: string | React.ReactNode;
  onFilesChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  multipleFileUpload?: boolean;
  startIcon?: React.ReactNode;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
}>) {
  return (
    <UploadButton
      variant="contained"
      component="label"
      sx={sx}
      fullWidth={fullWidth}
      disabled={disabled}
      startIcon={startIcon}
    >
      {typeof buttonLabel === 'string'
        ? <Typography variant="body2">{buttonLabel}</Typography>
        : buttonLabel}
      <input
        disabled={disabled}
        type="file"
        multiple={multipleFileUpload}
        hidden
        accept=".jpg, .jpeg, .png"
        onChange={onFilesChangeHandler}
      />
    </UploadButton>
  );
}

export function BusinessApplicationImageUploadButton({
  buttonLabel,
  businessApplication,
  folder,
  onSuccessfulUploadHandler,
  schemeId,
  imageIndex,
  multipleFilesUpload,
  buttonWidth,
  buttonFullWidth,
}: Readonly<{
  buttonLabel: string,
  businessApplication: BusinessApplication,
  folder: AllowedFileFolder,
  onSuccessfulUploadHandler: (businessApplication: BusinessApplication) => void
  schemeId?: string,
  imageIndex?: string | number,
  multipleFilesUpload?: boolean,
  buttonWidth?: string,
  buttonFullWidth?: boolean,
}>) {
  const [files, setFiles] = useState<File[]>([]);
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const onFilesChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFiles(Array.from(event.target.files));
      setIsPreviewDialogOpen(true);
    }
  };

  const onUploadConfirmHandler = async () => {
    try {
      setIsPreviewDialogOpen(false);
      setIsLoading(true);
      const result = await uploadBusinessApplicationFiles(
        files,
        businessApplication.businessId,
        folder,
        schemeId,
        imageIndex,
      );
      onSuccessfulUploadHandler(result.data);
      setFiles([]);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box textAlign="center">
      <StyledUploadButton
        buttonLabel={isLoading ? <CircularProgress size="20px" /> : buttonLabel}
        onFilesChangeHandler={onFilesChangeHandler}
        disabled={isLoading}
        multipleFileUpload={multipleFilesUpload}
        fullWidth={buttonFullWidth}
        startIcon={<BsCloudUpload />}
        sx={{
          width: buttonWidth,
        }}
      />
      {error && (
        <Typography color="error" marginTop="15px">
          Something went wrong while trying to upload the selected files
        </Typography>
      )}
      <ConfirmDialog
        dialogTitle="Do you want to upload the following image?"
        open={isPreviewDialogOpen}
        handleCancel={() => setIsPreviewDialogOpen(false)}
        handleConfirm={() => onUploadConfirmHandler()}
        maxWidth="md"
      >
        <Grid container spacing={2} alignItems="center" marginY="25px" paddingX="25px">
          {files.map((file) => (
            <Grid key={file.name} item xs={12} alignItems="center">
              <StyledImage width="100%" height="100%" src={URL.createObjectURL(file)} />
            </Grid>
          ))}
        </Grid>
        {(folder !== 'cover' && folder !== 'logo') && (
          <Typography align="center" paddingY="25px" color="error">
            Warning uploading new images will overwrite all existing images when you confirm saving changes
          </Typography>
        )}
      </ConfirmDialog>
    </Box>
  );
}
