import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
  DialogProps,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/system';
import { GiCancel, GiConfirmed } from 'react-icons/gi';

const StyledTitle = styled(Typography, {})({
  alignSelf: 'center',
  paddingTop: '20px',
  paddingLeft: '20px',
  paddingRight: '20px',
});

const StyledConfirm = styled(LoadingButton, {
  shouldForwardProp: (prop) => prop !== 'isDelete',
}) <{ isDelete?: boolean }>(({ theme, isDelete }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  color: theme.palette.common.white,
  backgroundColor: isDelete ? theme.palette.warning.main : theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: isDelete ? theme.palette.warning.main : theme.palette.secondary.main,
  },
}));

const StyledCancel = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isDelete',
}) <{ isDelete?: boolean }>(({ theme, isDelete }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  color: theme.palette.primary.white,
  backgroundColor: isDelete ? theme.palette.secondary.main : theme.palette.warning.main,
  '&:hover': {
    backgroundColor: isDelete ? theme.palette.secondary.main : theme.palette.warning.main,
  },
}));

// text description is optional. If isDelete is passed then colours for the
// buttons will change to match colouring style of the portal
type Props = Readonly<{
  dialogTitle: string,
  dialogText?: string,
  open: boolean,
  handleCancel: () => void,
  handleConfirm: () => void,
  isDelete?: boolean,
  disabled?: boolean,
  yesNo?: boolean,
  isLoading?: boolean,
  children?: React.ReactNode
  maxWidth?: DialogProps['maxWidth']
}>;

function ConfirmDialog(props: Props) {
  const {
    dialogTitle,
    dialogText,
    open,
    handleCancel,
    handleConfirm,
    isDelete,
    disabled,
    maxWidth,
    children,
    yesNo = false,
    isLoading = false,
  } = props;

  const handleClose = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    handleCancel();
  };

  return (
    <Dialog
      aria-label="confirm-dialog"
      onClose={(event, reason) => handleClose(event, reason)}
      open={open}
      fullWidth
      maxWidth={maxWidth || 'sm'}
      sx={{ zIndex: '3000' }}
    >
      <StyledTitle variant="h1">{dialogTitle}</StyledTitle>
      {dialogText ? (
        <DialogContent>
          <Grid item container xs={12} justifyContent="center">
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{dialogText}</Typography>
          </Grid>
        </DialogContent>
      ) : null}

      {children}

      <DialogActions>
        <StyledCancel
          variant="contained"
          fullWidth
          isDelete={isDelete}
          startIcon={<GiCancel />}
          onClick={handleCancel}
        >
          <Typography variant="body2">{yesNo ? 'NO' : 'CANCEL'}</Typography>
        </StyledCancel>
        <StyledConfirm
          loading={isLoading}
          variant="contained"
          fullWidth
          isDelete={isDelete}
          startIcon={<GiConfirmed />}
          disabled={disabled}
          onClick={handleConfirm}
        >
          <Typography variant="body2">{yesNo ? 'YES' : 'CONFIRM'}</Typography>
        </StyledConfirm>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
