import { useEffect, useState } from 'react';
import { getAllStripeAllowedProduct, StripeAllowedProduct } from '../../../Services/BillingService';

const useGetAllStripeAllowedProduct = (refresh?: boolean) => {
  const [stripeAllowedProducts, setStripeAllowedProducts] = useState<
  StripeAllowedProduct[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      try {
        setError(false);
        setIsLoading(true);
        const result = await getAllStripeAllowedProduct(
          abortController,
        );
        setStripeAllowedProducts(result.data || []);
      } catch (e: any) {
        if (e.name !== 'AbortError') {
          setError(true);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => abortController?.abort();
  }, [refresh]);

  return {
    stripeAllowedProducts,
    isLoading,
    error,
  };
};

export default useGetAllStripeAllowedProduct;
