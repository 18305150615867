/* eslint-disable max-len */

export type StripeCurrency = 'eur' | 'gbp' | 'aud' | 'cad' | 'aed' | 'usd';

export type BusinessApplication = {
  businessId: string;
  businessName: string;
  businessUserId: string;
  email: string;
  contact: string;
  phoneNumber: string;
  address: string;
  shortAddress: string;
  postalCode: string;
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  googlePlaceId: string;
  applicationStatus: BusinessApplicationStatus;
  tagOrderStatus: BusinessApplicationTagOrderStatus;
  tagDeliveryToEachLocation?: boolean | null;
  tagDeliveryAddress?: string | null;
  tagDeliveryTrackingNumber?: string | null;
  businessProfile: BusinessProfile;
  posIntegration?: BusinessApplicationPosIntegration | null;
  locations: BusinessLocation[];
  schemes: LoyaltyScheme[];
  billingInformation: BusinessApplicationBillingInfo;
  campaigns: BusinessApplicationCampaign[];
  notes: BusinessApplicationNote[];
  subscribers: string[];
  salesPerson?: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt?: string | null;
  deletedBy?: string | null;
};

export type BusinessApplicationCampaign = {
  schemeId: string;
  campaignType: 'birthday' | 'acquisition' | 'retention' | 'custom' | 'tag';
  rewardType: 'voucher' | 'stamp' | 'accelerator';
};

export type BusinessApplicationNote = {
  userName: string;
  createdAt: string;
  createdBy: string;
  content: string;
};

export type BusinessRegistration = {
  businessName: string;
  businessUserId: string;
  email: string;
  contact: string;
  phoneNumber: string;
  address: string;
  shortAddress: string;
  postalCode: string;
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  googlePlaceId: string;
  numberOfLocations: number;
};

export type BusinessApplicationBillingInfo = {
  taxId?: string | null;
  currency?: StripeCurrency | null;
  registeredBusinessName?: string | null;
  tagsPaymentIntentId?: string | null;
  useVolumeBaseBilling?: boolean | null;
  billingInterval?: 'month' | 'year';
  stripeCustomerId?: string | null;
  stripeDiscountId?: string | null;
  stripeProductId?: string | null;
  stripePriceId?: string | null;
  subscriptionStartingQuantity?: string | null;
};

export type BusinessApplicationAuditLog = {
  id: number;
  businessId: string;
  operation: BusinessApplicationAuditLogOperation;
  previousState: BusinessApplication;
  currentState: BusinessApplication;
  createdAt: string;
  createdBy: string;
};

export enum BusinessApplicationStatus {
  APPLICATION_STARTED = 'APPLICATION_STARTED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  CHANGES_REQUIRED = 'CHANGES_REQUIRED',
  LIVE_ON_SQUID = 'LIVE_ON_SQUID',
}

export enum BusinessApplicationTagOrderStatus {
  NOT_PURCHASED = 'NOT_PURCHASED',
  PURCHASED = 'PURCHASED',
  POSTED = 'POSTED',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED',
}

export enum BusinessApplicationPosIntegration {
  SQUARE = 'SQUARE',
}

export enum BusinessApplicationAuditLogOperation {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export type LoyaltyScheme = {
  schemeId: string;
  businessId: string;
  type: LoyaltySchemeType;
  addStamp: boolean;
  rewardText: string;
  rewardTextPlural?: string;
  costPrice?: number;
  sellPrice?: number;
  images: string[];
  restrictions?: {
    period: number;
    amount: number;
  };
  posPointsConversion?: number;
  posLoyaltySchemeType?: SchemePosLoyaltySchemeType;
  posRewardType?: SchemePosRewardType;
  posProgramType?: SchemePosProgramType;
  internalDescription?: string | null;
  designImages: string[];
  enabledLocations: string[];
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt?: string;
  deletedBy?: string;
};

export enum SchemePosLoyaltySchemeType {
  ALL = 'all',
  ITEM_SPECIFIC = 'itemSpecific',
}
export enum SchemePosRewardType {
  POINTS = 'points',
  STAMPS = 'stamps',
}
export enum SchemePosProgramType {
  SPEND_BASED = 'spendBased',
  QUANTITY_BASED = 'quantityBased',
}

export type BusinessLocation = {
  locationId: string;
  businessId: string;
  address?: string;
  shortAddress?: string;
  postalCode?: string;
  email?: string;
  country?: string;
  contact?: string;
  phoneNumber?: string;
  isLive: boolean;
  isTest: boolean;
  activationDatetime?: Date;
  churnDatetime?: Date;
  latitude: number;
  longitude: number;
  googlePlaceId?: string;
  openingHours: LocationOpeningHours;
  logo?: string | null;
  cover?: string | null;
  category?: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt?: string;
  deletedBy?: string;
};

export type LocationOpeningHours = {
  Monday?: string;
  Tuesday?: string;
  Wednesday?: string;
  Thursday?: string;
  Friday?: string;
  Saturday?: string;
  Sunday?: string;
};

export type LocationProfile = {
  locationId: string;
  description: string;
  logoImage: string;
  coverImage: string;
};

export type BusinessProfile = {
  businessId: string;
  description?: string | null;
  logoImage?: string | null;
  coverImage?: string | null;
  ourStoryDescription?: string | null;
  ourStoryImages: string[];
  ourSpaceDescription?: string | null;
  ourSpaceImages: string[];
  ourEthosDescription?: string | null;
  ourEthosImages: string[];
  ourTeamDescription?: string | null;
  ourTeamImages: string[];
  ourFoodDescription?: string | null;
  ourFoodImages: string[];
  ourFoodLinks: {
    menuLink?: string | null;
    orderLink?: string | null;
  };
  socialLinks: {
    website?: string | null;
    instagram?: string | null;
    youtube?: string | null;
    tiktok?: string | null;
    meta?: string | null;
    linkedin?: string | null;
    twitter?: string | null;
  };
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt?: string | null;
  deletedBy?: string | null;
};

export type AllowedFileFolder =
  | 'logo'
  | 'cover'
  | 'stampcard'
  | 'stampcard-design'
  | 'story'
  | 'space'
  | 'ethos'
  | 'team'
  | 'food';

type FindAllBusinessApplicationsResponse = {
  data: BusinessApplication[]
};

type UpdateBusinessApplicationResponse = {
  data: BusinessApplication
};

type GetBusinessApplicationAuditLogsResponse = {
  data: BusinessApplicationAuditLog[]
};

type UploadBusinessApplicationFilesResponse = {
  data: BusinessApplication
};

export const findAllBusinessApplications = async (
  abortController?: AbortController,
): Promise<FindAllBusinessApplicationsResponse> => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/businessApplications/v1/all`, {
    method: 'GET',
    signal: abortController?.signal,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      'Content-Type': 'application/json',
    },
  });

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return await result.json() as FindAllBusinessApplicationsResponse;
};

export const updateBusinessApplication = async (
  businessApplication: BusinessApplication,
  abortController?: AbortController,
): Promise<UpdateBusinessApplicationResponse> => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessApplication.businessId}`, {
    method: 'PUT',
    signal: abortController?.signal,
    body: JSON.stringify(businessApplication),
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      'Content-Type': 'application/json',
    },
  });

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return await result.json() as UpdateBusinessApplicationResponse;
};

export const addBusinessApplicationNotes = async (
  businessId: string,
  content: string,
  abortController?: AbortController,
): Promise<UpdateBusinessApplicationResponse> => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessId}/note`, {
    method: 'POST',
    signal: abortController?.signal,
    body: JSON.stringify({
      content,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      'Content-Type': 'application/json',
    },
  });

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return await result.json() as UpdateBusinessApplicationResponse;
};

export const subscribeToBusinessApplication = async (
  businessId:string,
  abortController?: AbortController,
): Promise<UpdateBusinessApplicationResponse> => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessId}/subscribe`, {
    method: 'POST',
    signal: abortController?.signal,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      'Content-Type': 'application/json',
    },
  });

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return await result.json() as UpdateBusinessApplicationResponse;
};

export const unsubscribeToBusinessApplication = async (
  businessId: string,
  abortController?: AbortController,
): Promise<UpdateBusinessApplicationResponse> => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessId}/unsubscribe`, {
    method: 'POST',
    signal: abortController?.signal,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      'Content-Type': 'application/json',
    },
  });

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return await result.json() as UpdateBusinessApplicationResponse;
};

export const assignBusinessApplication = async (
  businessId: string,
  abortController?: AbortController,
): Promise<UpdateBusinessApplicationResponse> => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessId}/assign`, {
    method: 'POST',
    signal: abortController?.signal,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      'Content-Type': 'application/json',
    },
  });

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return await result.json() as UpdateBusinessApplicationResponse;
};

export const unassignBusinessApplication = async (
  businessId: string,
  abortController?: AbortController,
): Promise<UpdateBusinessApplicationResponse> => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessId}/unassign`, {
    method: 'POST',
    signal: abortController?.signal,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      'Content-Type': 'application/json',
    },
  });

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return await result.json() as UpdateBusinessApplicationResponse;
};

export const revertBusinessApplication = async (
  businessId: string,
  auditLogId: string | number,
  abortController?: AbortController,
): Promise<UpdateBusinessApplicationResponse> => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessId}/revert/${auditLogId}`, {
    method: 'PUT',
    signal: abortController?.signal,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      'Content-Type': 'application/json',
    },
  });

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return await result.json() as UpdateBusinessApplicationResponse;
};

export const getBusinessApplicationAuditLogsByBusinessId = async (
  businessId: string,
  abortController?: AbortController,
): Promise<GetBusinessApplicationAuditLogsResponse> => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessId}/auditLogs`, {
    method: 'GET',
    signal: abortController?.signal,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      'Content-Type': 'application/json',
    },
  });

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return await result.json() as GetBusinessApplicationAuditLogsResponse;
};

export const uploadBusinessApplicationFiles = async (
  files: File[],
  businessId: string,
  folder: AllowedFileFolder,
  schemeId?: string,
  imageIndex?: string | number,
  abortController?: AbortController,
): Promise<UploadBusinessApplicationFilesResponse> => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessId}/images/${folder}`);
  url.search = new URLSearchParams({
    ...(schemeId ? { schemeId } : {}),
    ...(imageIndex ? { imageIndex: imageIndex.toString() } : {}),
  }).toString();

  const formData = new FormData();
  files.forEach((file) => {
    formData.append('image', file);
  });

  const result = await fetch(url, {
    method: 'POST',
    signal: abortController?.signal,
    body: formData,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return await result.json() as UploadBusinessApplicationFilesResponse;
};

export const isUserSubscribedToBusinessApplication = (
  businessApplication: BusinessApplication,
): boolean => businessApplication.subscribers?.includes(localStorage.getItem('userEmail')!);

export const isUserAssignedToBusinessApplication = (
  businessApplication: BusinessApplication,
): boolean => businessApplication.salesPerson === localStorage.getItem('userEmail')!;

export const generateBusinessApplicationLocation = (
  businessApplication: BusinessApplication,
  locationNumber: number,
): BusinessLocation => ({
  // Then we'll see if we need to replicate business application data or if we want to send back just an empty object
  email: businessApplication.email,
  isLive: false,
  isTest: false,
  address: businessApplication.address,
  contact: businessApplication.contact,
  country: businessApplication.country,
  latitude: 0,
  longitude: 0,
  businessId: businessApplication.businessId,
  locationId: `${businessApplication.businessId}-${locationNumber.toString().padStart(3, '0')}`,
  phoneNumber: businessApplication.phoneNumber,
  openingHours: {
    Friday: '00:00-00:00',
    Monday: '00:00-00:00',
    Sunday: '00:00-00:00',
    Tuesday: '00:00-00:00',
    Saturday: '00:00-00:00',
    Thursday: '00:00-00:00',
    Wednesday: '00:00-00:00',
  },
  shortAddress: businessApplication.shortAddress,
  postalCode: businessApplication.postalCode,
  googlePlaceId: businessApplication.googlePlaceId,
  logo: businessApplication.businessProfile.logoImage,
  cover: businessApplication.businessProfile.logoImage,
  createdAt: new Date().toISOString(),
  createdBy: localStorage.getItem('userId')!,
  updatedAt: new Date().toISOString(),
  updatedBy: localStorage.getItem('userId')!,
});

export enum LoyaltySchemeType {
  STAMP_CARD = 'STAMP_CARD',
  POS = 'POS',
}

export const generateBusinessApplicationLoyaltyScheme = (
  businessApplication: BusinessApplication,
  schemeNumber: number,
): LoyaltyScheme => ({
  schemeId: `${businessApplication.businessId}${!schemeNumber ? '' : `_${schemeNumber}`}`,
  businessId: businessApplication.businessId,
  type: LoyaltySchemeType.STAMP_CARD,
  addStamp: false,
  rewardText: '1 Free coffee',
  restrictions: {
    amount: 1, // amount of stamps allowed within the period specified
    period: 1, // period in seconds
  },
  rewardTextPlural: '',
  costPrice: 0,
  sellPrice: 0,
  images: [],
  internalDescription: null,
  designImages: [],
  enabledLocations: [],
  createdAt: new Date().toISOString(),
  createdBy: localStorage.getItem('userId')!,
  updatedAt: new Date().toISOString(),
  updatedBy: localStorage.getItem('userId')!,
});

export const findBusinessApplicationByBusinessId = (
  businessId: string,
): Promise<BusinessApplication> => ({ businessId } as unknown as Promise<BusinessApplication>);

export const findBusinessApplicationByUserId = (
  userId: string,
): Promise<BusinessApplication> => ({ userId } as unknown as Promise<BusinessApplication>);

export const createBusinessApplication = (
  businessRegistration: BusinessRegistration,
): Promise<BusinessApplication> => ({ businessRegistration } as unknown as Promise<BusinessApplication>);

export const deleteBusinessApplication = (
  businessId: string,
  userId: string,
): Promise<void> => ({ businessId, userId } as unknown as Promise<void>);
