import React from 'react';
import {
  Button, IconButton, Typography,
} from '@mui/material';
import { styled } from '@mui/system';

const Styled = styled(Button)<Readonly<{
  fullWidth?: boolean;
  width?: string;
  height?: string;
}>>(({
  theme, fullWidth, width, height,
}) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  height: height || '36px',
  width: width || (fullWidth ? '100%' : '110px'),
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

type Props = Readonly<{
  children: React.ReactNode,
  disabled?: boolean,
  fullWidth?: boolean,
  width?: string,
  height?: string,
  onClick?: () => void,
  startIcon?: React.ReactNode
  type?: 'button' | 'reset' | 'submit',
  withChildComponent?: boolean
}>;

export function StyledButton({
  disabled, onClick, startIcon, children, fullWidth, width, height, type, withChildComponent,
}: Props) {
  return (
    <Styled
      disabled={disabled}
      fullWidth={fullWidth}
      width={width}
      height={height}
      aria-label="save"
      variant="contained"
      startIcon={startIcon || null}
      onClick={onClick}
      type={type}
    >
      {withChildComponent ? children : <Typography variant="body2">{children}</Typography>}
    </Styled>
  );
}

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));
