import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Card,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { BsPlusSquareFill } from 'react-icons/bs';
import { AiFillDelete } from 'react-icons/ai';
import {
  BusinessApplication,
  BusinessLocation,
  generateBusinessApplicationLocation,
  updateBusinessApplication,
} from '../../../Services/BusinessApplicationService';
import {
  CardTitle,
  CardTitleText,
  ExpandedContent,
  ExpandMore,
  FieldErrorMessage,
  StyledButton,
  StyledIconButton,
} from './shared';
import GoogleMapContainer from '../../../Components/BasicComponents/GoogleMapContainer';
import { openingHoursFormatErrorMessage, openingHoursPatternValidation, requiredFieldErrorMessage } from '../utils';

function BusinessApplicationLocationsCard({
  locationData,
  locationIndex,
  locationsCount,
  onRemoveLocationHandler,
}: Readonly<{
  locationData: BusinessLocation;
  locationIndex: number;
  locationsCount: number;
  onRemoveLocationHandler: (locationIndex: number) => void;
}>) {
  const { register, formState } = useFormContext<{ locations: BusinessLocation[] }>();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Card sx={{
      width: '100%',
      '&:not(:first-of-type)': {
        marginTop: '15px',
      },
    }}
    >
      <CardTitle>
        <CardTitleText variant="h4">{locationData.locationId}</CardTitleText>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <StyledIconButton
            size="small"
            disabled={locationsCount === 1}
            onClick={() => onRemoveLocationHandler(locationIndex)}
          >
            <AiFillDelete />
          </StyledIconButton>
          <ExpandMore
            expand={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <Grid container gap={3} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h4">General details</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.contact`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                required
                defaultValue={locationData.contact}
                error={!!formState.errors.locations?.[locationIndex]?.contact}
                label="Contact"
              />
              {formState.errors.locations?.[locationIndex]?.contact && (
                <FieldErrorMessage>
                  {formState.errors.locations?.[locationIndex]?.contact?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.email`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                required
                defaultValue={locationData.email}
                error={!!formState.errors.locations?.[locationIndex]?.email}
                label="Email"
              />
              {formState.errors.locations?.[locationIndex]?.email && (
                <FieldErrorMessage>
                  {formState.errors.locations?.[locationIndex]?.email?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.phoneNumber`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                required
                defaultValue={locationData.phoneNumber}
                error={!!formState.errors.locations?.[locationIndex]?.phoneNumber}
                label="Phone number"
              />
              {formState.errors.locations?.[locationIndex]?.phoneNumber && (
                <FieldErrorMessage>
                  {formState.errors.locations?.[locationIndex]?.phoneNumber?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Address details</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.address`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                required
                defaultValue={locationData.address}
                error={!!formState.errors.locations?.[locationIndex]?.address}
                label="Address"
              />
              {formState.errors.locations?.[locationIndex]?.address && (
                <FieldErrorMessage>
                  {formState.errors.locations?.[locationIndex]?.address?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.shortAddress`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                required
                defaultValue={locationData.shortAddress}
                error={!!formState.errors.locations?.[locationIndex]?.shortAddress}
                label="Short address"
              />
              {formState.errors.locations?.[locationIndex]?.shortAddress && (
                <FieldErrorMessage>
                  {formState.errors.locations?.[locationIndex]?.shortAddress?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.postalCode`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                required
                defaultValue={locationData.postalCode}
                error={!!formState.errors.locations?.[locationIndex]?.postalCode}
                label="Postal code"
              />
              {formState.errors.locations?.[locationIndex]?.postalCode && (
                <FieldErrorMessage>
                  {formState.errors.locations?.[locationIndex]?.postalCode?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.country`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                required
                defaultValue={locationData.country}
                error={!!formState.errors.locations?.[locationIndex]?.country}
                label="Country"
              />
              {formState.errors.locations?.[locationIndex]?.country && (
                <FieldErrorMessage>
                  {formState.errors.locations?.[locationIndex]?.country?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.googlePlaceId`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                required
                defaultValue={locationData.googlePlaceId || 0}
                error={!!formState.errors.locations?.[locationIndex]?.googlePlaceId}
                label="Google place ID"
              />
              {formState.errors.locations?.[locationIndex]?.googlePlaceId && (
                <FieldErrorMessage>
                  {formState.errors.locations?.[locationIndex]?.googlePlaceId?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.latitude`, {
                  required: requiredFieldErrorMessage,
                  valueAsNumber: true,
                })}
                fullWidth
                required
                defaultValue={locationData.latitude || 0}
                error={!!formState.errors.locations?.[locationIndex]?.latitude}
                label="Latitude"
              />
              {formState.errors.locations?.[locationIndex]?.latitude && (
                <FieldErrorMessage>
                  {formState.errors.locations?.[locationIndex]?.latitude?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.longitude`, {
                  required: requiredFieldErrorMessage,
                  valueAsNumber: true,
                })}
                fullWidth
                required
                defaultValue={locationData.longitude || 0}
                error={!!formState.errors.locations?.[locationIndex]?.longitude}
                label="Longitude"
              />
              {formState.errors.locations?.[locationIndex]?.longitude && (
                <FieldErrorMessage>
                  {formState.errors.locations?.[locationIndex]?.longitude?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <GoogleMapContainer
                lat={locationData.latitude}
                lng={locationData.longitude}
                zoom={17}
                enableMapOptions={false}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Opening hours</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.openingHours.Monday`, {
                  required: requiredFieldErrorMessage,
                  pattern: {
                    value: openingHoursPatternValidation,
                    message: openingHoursFormatErrorMessage,
                  },
                })}
                fullWidth
                required
                defaultValue={locationData.openingHours.Monday}
                error={!!formState.errors.locations?.[locationIndex]?.openingHours?.Monday}
                label="Monday"
              />
              {formState.errors.locations?.[locationIndex]?.openingHours?.Monday?.message && (
                <FieldErrorMessage>
                  {formState.errors.locations?.[locationIndex]?.openingHours?.Monday?.message}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.openingHours.Tuesday`, {
                  required: requiredFieldErrorMessage,
                  pattern: {
                    value: openingHoursPatternValidation,
                    message: openingHoursFormatErrorMessage,
                  },
                })}
                fullWidth
                required
                defaultValue={locationData.openingHours.Tuesday}
                error={!!formState.errors.locations?.[locationIndex]?.openingHours?.Tuesday}
                label="Tuesday"
              />
              {formState.errors.locations?.[locationIndex]?.openingHours?.Tuesday?.message && (
                <FieldErrorMessage>
                  {formState.errors.locations?.[locationIndex]?.openingHours?.Tuesday?.message}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.openingHours.Wednesday`, {
                  required: requiredFieldErrorMessage,
                  pattern: {
                    value: openingHoursPatternValidation,
                    message: openingHoursFormatErrorMessage,
                  },
                })}
                fullWidth
                required
                defaultValue={locationData.openingHours.Wednesday}
                error={!!formState.errors.locations?.[locationIndex]?.openingHours?.Wednesday}
                label="Wednesday"
              />
              {formState.errors.locations?.[locationIndex]?.openingHours?.Wednesday?.message && (
                <FieldErrorMessage>
                  {formState.errors.locations?.[locationIndex]?.openingHours?.Wednesday?.message}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.openingHours.Thursday`, {
                  required: requiredFieldErrorMessage,
                  pattern: {
                    value: openingHoursPatternValidation,
                    message: openingHoursFormatErrorMessage,
                  },
                })}
                fullWidth
                required
                defaultValue={locationData.openingHours.Thursday}
                error={!!formState.errors.locations?.[locationIndex]?.openingHours?.Thursday}
                label="Thursday"
              />
              {formState.errors.locations?.[locationIndex]?.openingHours?.Thursday?.message && (
                <FieldErrorMessage>
                  {formState.errors.locations?.[locationIndex]?.openingHours?.Thursday?.message}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.openingHours.Friday`, {
                  required: requiredFieldErrorMessage,
                  pattern: {
                    value: openingHoursPatternValidation,
                    message: openingHoursFormatErrorMessage,
                  },
                })}
                fullWidth
                required
                defaultValue={locationData.openingHours.Friday}
                error={!!formState.errors.locations?.[locationIndex]?.openingHours?.Friday}
                label="Friday"
              />
              {formState.errors.locations?.[locationIndex]?.openingHours?.Friday?.message && (
                <FieldErrorMessage>
                  {formState.errors.locations?.[locationIndex]?.openingHours?.Friday?.message}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.openingHours.Saturday`, {
                  required: requiredFieldErrorMessage,
                  pattern: {
                    value: openingHoursPatternValidation,
                    message: openingHoursFormatErrorMessage,
                  },
                })}
                fullWidth
                required
                defaultValue={locationData.openingHours.Saturday}
                error={!!formState.errors.locations?.[locationIndex]?.openingHours?.Saturday}
                label="Saturday"
              />
              {formState.errors.locations?.[locationIndex]?.openingHours?.Saturday?.message && (
                <FieldErrorMessage>
                  {formState.errors.locations?.[locationIndex]?.openingHours?.Saturday?.message}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.openingHours.Sunday`, {
                  required: requiredFieldErrorMessage,
                  pattern: {
                    value: openingHoursPatternValidation,
                    message: openingHoursFormatErrorMessage,
                  },
                })}
                fullWidth
                required
                defaultValue={locationData.openingHours.Sunday}
                error={!!formState.errors.locations?.[locationIndex]?.openingHours?.Sunday}
                label="Sunday"
              />
              {formState.errors.locations?.[locationIndex]?.openingHours?.Sunday?.message && (
                <FieldErrorMessage>
                  {formState.errors.locations?.[locationIndex]?.openingHours?.Sunday?.message}
                </FieldErrorMessage>
              )}
            </Grid>
          </Grid>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

type FieldArrayLocation = BusinessLocation & { id: string };

function BusinessApplicationLocationsDetails({
  businessApplication,
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  businessApplication: BusinessApplication;
  onSetBusinessApplicationToEditHandler: (
    businessApplication: BusinessApplication
  ) => void;
}>) {
  const formMethods = useForm<BusinessApplication>()!;
  const { fields, replace, append } = useFieldArray({
    control: formMethods.control,
    name: 'locations' as unknown as never,
  });
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (businessApplication.locations.length > 0) {
      replace(businessApplication.locations);
    }
  }, [businessApplication.updatedAt]);

  const onAddNewLocationHandler = () => {
    // Because locations are not live yet nor in the actual db
    // we can avoid checking available locationId suffix numbering
    append(
      generateBusinessApplicationLocation(
        businessApplication,
        fields.length + 1,
      ),
    );
  };

  const onRemoveLocationHandler = useCallback((locationIndex: number) => {
    const updatedFields: FieldArrayLocation[] = [];

    (fields as unknown as FieldArrayLocation[]).forEach((location, index) => {
      const currentLocationNumber = parseInt(
        location.locationId.split('-')[1],
        10,
      );

      if (index < locationIndex) {
        updatedFields.push(location);
        return;
      }

      if (index !== locationIndex && index > locationIndex) {
        updatedFields.push({
          ...location,
          locationId: `${location.businessId}-${(
            currentLocationNumber - 1
          )
            .toString()
            .padStart(3, '0')}`,
        });
      }
    });
    replace(updatedFields);
  }, [fields.length]);

  const onFormSubmitHandler = async (data: { locations: BusinessLocation[] }) => {
    try {
      setError(false);
      setIsLoading(true);
      const result = await updateBusinessApplication(
        { ...businessApplication, locations: data.locations },
      );
      onSetBusinessApplicationToEditHandler(result.data);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardTitle>
        <CardTitleText variant="h4">Locations details</CardTitleText>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <StyledIconButton
            disabled={!isExpanded}
            onClick={() => onAddNewLocationHandler()}
            size="small"
          >
            <BsPlusSquareFill />
          </StyledIconButton>
          <ExpandMore
            expand={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          {fields.length === 0 && (
            <Typography variant="h6" textAlign="center">
              No locations to edit
            </Typography>
          )}
          {fields.length !== 0 && (
            <form onSubmit={formMethods.handleSubmit(onFormSubmitHandler)}>
              <FormProvider {...formMethods}>
                {fields.map((locationData, locationIndex) => (
                  <BusinessApplicationLocationsCard
                    key={locationData.id}
                    locationData={locationData as unknown as BusinessLocation}
                    locationIndex={locationIndex}
                    locationsCount={fields.length}
                    onRemoveLocationHandler={onRemoveLocationHandler}
                  />
                ))}
              </FormProvider>
              <Grid item xs={12} sm={12} sx={{ marginTop: '20px' }}>
                <StyledButton
                  disabled={isLoading}
                  fullWidth
                  type="submit"
                  withChildComponent={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size="20px" />
                  ) : (
                    'Save locations'
                  )}
                </StyledButton>
                {formMethods.formState.errors?.locations && (
                  <Typography
                    variant="body2"
                    color="error"
                    textAlign="center"
                    marginTop="15px"
                  >
                    One of more locations are missing required fields
                  </Typography>
                )}
                {error && (
                  <Typography
                    variant="body2"
                    color="error"
                    textAlign="center"
                    marginTop="15px"
                  >
                    Something went wrong while submitting the update, please try
                    again or contact support
                  </Typography>
                )}
              </Grid>
            </form>
          )}
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default BusinessApplicationLocationsDetails;
