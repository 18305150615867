/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Card, CircularProgress, Collapse, Divider, Grid, MenuItem, TextField, Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import {
  FormProvider, useFieldArray, useForm, useFormContext,
} from 'react-hook-form';
import { AiFillDelete } from 'react-icons/ai';
import { BsPlusSquareFill } from 'react-icons/bs';
import {
  BusinessApplication,
  generateBusinessApplicationLoyaltyScheme,
  LoyaltyScheme,
  LoyaltySchemeType,
  SchemePosLoyaltySchemeType,
  SchemePosProgramType,
  SchemePosRewardType,
  updateBusinessApplication,
} from '../../../Services/BusinessApplicationService';
import {
  BusinessApplicationImageUploadButton,
  CardTitle,
  CardTitleText,
  ExpandedContent,
  ExpandMore,
  FieldErrorMessage,
  MultiImagesPreview,
  StyledButton,
  StyledIconButton,
} from './shared';
import { requiredFieldErrorMessage } from '../utils';

function BusinessApplicationSchemaCard({
  businessApplication,
  schemeData,
  schemeIndex,
  schemesCount,
  onRemoveSchemeHandler,
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  businessApplication: BusinessApplication,
  schemeData: LoyaltyScheme,
  schemeIndex: number,
  schemesCount: number,
  onRemoveSchemeHandler: (schemeIndex: number) => void,
  onSetBusinessApplicationToEditHandler: (businessApplication: BusinessApplication) => void
}>) {
  const {
    register, formState, watch, unregister,
  } = useFormContext<{ schemes: LoyaltyScheme[] }>();
  const [isExpanded, setIsExpanded] = useState(false);
  const showPosFields = watch(`schemes.${schemeIndex}.type`);

  useEffect(() => {
    if (showPosFields === LoyaltySchemeType.STAMP_CARD) {
      unregister(`schemes.${schemeIndex}.posPointsConversion`);
      unregister(`schemes.${schemeIndex}.posLoyaltySchemeType`);
      unregister(`schemes.${schemeIndex}.posRewardType`);
      unregister(`schemes.${schemeIndex}.posProgramType`);
    }
  }, [showPosFields]);

  return (
    <Card sx={{
      width: '100%',
      '&:not(:first-of-type)': {
        marginTop: '15px',
      },
    }}
    >
      <CardTitle>
        <CardTitleText variant="h4">{schemeData.schemeId}</CardTitleText>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <StyledIconButton
            size="small"
            disabled={schemesCount === 1}
            onClick={() => onRemoveSchemeHandler(schemeIndex)}
          >
            <AiFillDelete />
          </StyledIconButton>
          <ExpandMore
            expand={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <Grid container gap={3} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h4">General details</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.schemeId`, {
                  required: requiredFieldErrorMessage,
                })}
                required
                fullWidth
                defaultValue={schemeData.schemeId}
                error={!!formState.errors.schemes?.[schemeIndex]?.schemeId}
                label="Scheme ID"
              />
              {formState.errors.schemes?.[schemeIndex]?.schemeId && (
                <FieldErrorMessage>
                  {formState.errors.schemes?.[schemeIndex]?.schemeId?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.internalDescription`)}
                required
                fullWidth
                multiline
                minRows={3}
                defaultValue={schemeData.internalDescription}
                error={!!formState.errors.schemes?.[schemeIndex]?.internalDescription}
                label="Internal scheme description"
              />
              {formState.errors.schemes?.[schemeIndex]?.internalDescription && (
                <FieldErrorMessage>
                  {formState.errors.schemes?.[schemeIndex]?.internalDescription?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Scheme type</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.type`, {
                  required: requiredFieldErrorMessage,
                })}
                required
                fullWidth
                select
                defaultValue={schemeData.type}
                label="Scheme Type"
              >
                <MenuItem value={LoyaltySchemeType.STAMP_CARD}>STAMP CARD</MenuItem>
                <MenuItem value={LoyaltySchemeType.POS}>POS</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Allowed locations</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.enabledLocations`, {
                  required: requiredFieldErrorMessage,
                })}
                required
                fullWidth
                select
                defaultValue={schemeData.enabledLocations}
                label="Enabled locations"
              >
                <MenuItem value="ALL">ALL</MenuItem>
                {businessApplication.locations.map((loc) => <MenuItem key={loc.locationId} value={loc.locationId}>{loc.locationId}</MenuItem>)}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Restrictions</Typography>
              <Typography variant="subtitle1">
                Restriction will stop multiple stamps in quick succession.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.restrictions.amount`, {
                  required: requiredFieldErrorMessage,
                  valueAsNumber: true,
                })}
                required
                fullWidth
                defaultValue={schemeData.restrictions?.amount}
                error={!!formState.errors.schemes?.[schemeIndex]?.restrictions?.amount}
                label="The Amount specifies how many taps before timeout"
              />
              {formState.errors.schemes?.[schemeIndex]?.restrictions?.amount && (
                <FieldErrorMessage>
                  {formState.errors.schemes?.[schemeIndex]?.restrictions?.amount?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.restrictions.period`, {
                  required: requiredFieldErrorMessage,
                  valueAsNumber: true,
                })}
                required
                fullWidth
                defaultValue={schemeData.restrictions?.period}
                error={!!formState.errors.schemes?.[schemeIndex]?.restrictions?.period}
                label="The Period (seconds) specifies how many seconds until they can tap again"
              />
              {formState.errors.schemes?.[schemeIndex]?.restrictions?.period && (
                <FieldErrorMessage>
                  {formState.errors.schemes?.[schemeIndex]?.restrictions?.period?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Reward</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.addStamp`, {
                  setValueAs: (v) => Boolean(v),
                })}
                required
                fullWidth
                select
                defaultValue={Number(schemeData.addStamp || 0)}
                label="Add stamp on tap"
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.rewardText`, {
                  required: requiredFieldErrorMessage,
                })}
                required
                fullWidth
                defaultValue={schemeData.rewardText}
                error={!!formState.errors.schemes?.[schemeIndex]?.rewardText}
                label="Reward text"
              />
              {formState.errors.schemes?.[schemeIndex]?.rewardText && (
                <FieldErrorMessage>
                  {formState.errors.schemes?.[schemeIndex]?.rewardText?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.rewardTextPlural`)}
                fullWidth
                defaultValue={schemeData.schemeId}
                error={!!formState.errors.schemes?.[schemeIndex]?.rewardTextPlural}
                label="Reward text plural"
              />
              {formState.errors.schemes?.[schemeIndex]?.rewardTextPlural && (
                <FieldErrorMessage>
                  {formState.errors.schemes?.[schemeIndex]?.rewardTextPlural?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Prices - ROI settings</Typography>
              <Typography variant="subtitle1">
                ROI is calculated by using the number of stamps and multiple by the Sell Price minus the in house Cost Price.
              </Typography>
              <Typography variant="subtitle1">
                ROI = (Stamps * Sell Price) - (Stamps * Cost Price)
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.costPrice`, {
                  valueAsNumber: true,
                })}
                fullWidth
                defaultValue={schemeData.costPrice}
                type="number"
                error={!!formState.errors.schemes?.[schemeIndex]?.costPrice}
                label="Cost price"
              />
              {formState.errors.schemes?.[schemeIndex]?.costPrice && (
                <FieldErrorMessage>
                  {formState.errors.schemes?.[schemeIndex]?.costPrice?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.sellPrice`, {
                  valueAsNumber: true,
                })}
                fullWidth
                defaultValue={schemeData.sellPrice}
                type="number"
                error={!!formState.errors.schemes?.[schemeIndex]?.sellPrice}
                label="Sell price"
              />
              {formState.errors.schemes?.[schemeIndex]?.sellPrice && (
                <FieldErrorMessage>
                  {formState.errors.schemes?.[schemeIndex]?.sellPrice?.message as string}
                </FieldErrorMessage>
              )}
            </Grid>

            {showPosFields === LoyaltySchemeType.POS && (
              <>
                <Grid item xs={12}>
                  <Divider style={{ margin: '25px 0' }} />
                  <Typography variant="h4">POS Scheme details</Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    {...register(`schemes.${schemeIndex}.posPointsConversion`, {
                      required: requiredFieldErrorMessage,
                      valueAsNumber: true,
                    })}
                    fullWidth
                    defaultValue={schemeData.posPointsConversion || 1}
                    type="number"
                    error={!!formState.errors.schemes?.[schemeIndex]?.posPointsConversion}
                    label="POS points conversion"
                  />
                  {formState.errors.schemes?.[schemeIndex]?.posPointsConversion && (
                    <FieldErrorMessage>
                      {formState.errors.schemes?.[schemeIndex]?.posPointsConversion?.message as string}
                    </FieldErrorMessage>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    {...register(`schemes.${schemeIndex}.posLoyaltySchemeType`)}
                    fullWidth
                    select
                    defaultValue={schemeData.posLoyaltySchemeType || SchemePosLoyaltySchemeType.ALL}
                    label="POS Scheme type"
                  >
                    <MenuItem value={SchemePosLoyaltySchemeType.ALL}>ALL</MenuItem>
                    <MenuItem value={SchemePosLoyaltySchemeType.ITEM_SPECIFIC}>ITEM SPECIFIC</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    {...register(`schemes.${schemeIndex}.posRewardType`)}
                    fullWidth
                    select
                    defaultValue={schemeData.posRewardType || SchemePosRewardType.POINTS}
                    label="Reward type"
                  >
                    <MenuItem value={SchemePosRewardType.POINTS}>POINTS</MenuItem>
                    <MenuItem value={SchemePosRewardType.STAMPS}>STAMPS</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    {...register(`schemes.${schemeIndex}.posProgramType`)}
                    fullWidth
                    select
                    defaultValue={schemeData.posProgramType || SchemePosProgramType.SPEND_BASED}
                    label="Program type"
                  >
                    <MenuItem value={SchemePosProgramType.SPEND_BASED}>SPEND BASED</MenuItem>
                    <MenuItem value={SchemePosProgramType.QUANTITY_BASED}>QUANTITY BASED</MenuItem>
                  </TextField>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Scheme design images</Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <MultiImagesPreview
                images={schemeData.images}
                noImageChild="No scheme design images"
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <BusinessApplicationImageUploadButton
                buttonLabel="Upload scheme design images"
                businessApplication={businessApplication}
                folder="stampcard-design"
                schemeId={schemeData.schemeId}
                multipleFilesUpload
                buttonFullWidth
                onSuccessfulUploadHandler={onSetBusinessApplicationToEditHandler}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Scheme images</Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <MultiImagesPreview
                images={schemeData.images}
                noImageChild="No scheme images"
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <BusinessApplicationImageUploadButton
                buttonLabel="Upload scheme images"
                businessApplication={businessApplication}
                folder="stampcard"
                schemeId={schemeData.schemeId}
                multipleFilesUpload
                buttonFullWidth
                onSuccessfulUploadHandler={onSetBusinessApplicationToEditHandler}
              />
            </Grid>
          </Grid>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

type FieldArrayScheme = LoyaltyScheme & { id: string };

function BusinessApplicationSchemesDetails({
  businessApplication,
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  businessApplication: BusinessApplication;
  onSetBusinessApplicationToEditHandler: (
    businessApplication: BusinessApplication
  ) => void;
}>) {
  const formMethods = useForm<BusinessApplication>();
  const { fields, replace, append } = useFieldArray({
    control: formMethods.control,
    name: 'schemes' as unknown as never,
  });

  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (businessApplication.schemes.length > 0) {
      replace(businessApplication.schemes);
    }
  }, [businessApplication.updatedAt]);

  const onAddNewSchemeHandler = useCallback(() => {
    // Because schemes are not live yet nor in the actual db
    // we can avoid checking available locationId suffix numbering
    append(
      generateBusinessApplicationLoyaltyScheme(
        businessApplication,
        fields.length,
      ),
    );
  }, [fields.length, businessApplication.updatedAt]);

  const onRemoveSchemeHandler = useCallback((schemeIndex: number) => {
    const updatedFields: FieldArrayScheme[] = [];

    (fields as unknown as FieldArrayScheme[]).forEach((scheme, index) => {
      const currentLocationNumber = parseInt(
        scheme.schemeId.split('_')[1],
        10,
      );

      if (index < schemeIndex || !currentLocationNumber) {
        updatedFields.push(scheme);
        return;
      }

      if (index !== schemeIndex && index > schemeIndex) {
        updatedFields.push({
          ...scheme,
          schemeId: `${scheme.businessId}_${currentLocationNumber - 1}`,
        });
      }
    });
    replace(updatedFields);
  }, [fields.length, businessApplication.updatedAt]);

  const onFormSubmitHandler = useCallback(async (data: { schemes: LoyaltyScheme[] }) => {
    try {
      setError(false);
      setIsLoading(true);
      const result = await updateBusinessApplication(
        { ...businessApplication, schemes: data.schemes },
      );
      onSetBusinessApplicationToEditHandler(result.data);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, [businessApplication.updatedAt]);

  return (
    <Card sx={{ width: '100%' }}>
      <CardTitle>
        <CardTitleText variant="h4">Schemes details</CardTitleText>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <StyledIconButton
            disabled={!isExpanded}
            onClick={() => onAddNewSchemeHandler()}
            size="small"
          >
            <BsPlusSquareFill />
          </StyledIconButton>
          <ExpandMore
            expand={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          {fields.length === 0 && (
            <Typography variant="h6" textAlign="center">
              No schemes to edit
            </Typography>
          )}
          {fields.length !== 0 && (
            <form onSubmit={formMethods.handleSubmit(onFormSubmitHandler)}>
              <FormProvider {...formMethods}>
                {fields.map((schemeData, schemeIndex) => (
                  <BusinessApplicationSchemaCard
                    key={schemeData.id}
                    schemeData={schemeData as unknown as LoyaltyScheme}
                    businessApplication={businessApplication}
                    schemeIndex={schemeIndex}
                    schemesCount={fields.length}
                    onRemoveSchemeHandler={onRemoveSchemeHandler}
                    onSetBusinessApplicationToEditHandler={onSetBusinessApplicationToEditHandler}
                  />
                ))}
              </FormProvider>
              <Grid item xs={12} sx={{ marginTop: '20px' }}>
                <StyledButton
                  disabled={isLoading}
                  fullWidth
                  type="submit"
                  withChildComponent={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size="20px" />
                  ) : (
                    'Save schemes'
                  )}
                </StyledButton>
                {formMethods.formState.errors?.schemes && (
                  <Typography
                    variant="body2"
                    color="error"
                    textAlign="center"
                    marginTop="15px"
                  >
                    One of more schemes are missing required fields
                  </Typography>
                )}
                {error && (
                  <Typography
                    variant="body2"
                    color="error"
                    textAlign="center"
                    marginTop="15px"
                  >
                    Something went wrong while submitting the update, please try
                    again or contact support
                  </Typography>
                )}
              </Grid>
            </form>
          )}
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default BusinessApplicationSchemesDetails;
