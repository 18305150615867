import {
  BusinessApplicationStatus,
  BusinessApplicationTagOrderStatus,
} from '../../../Services/BusinessApplicationService';

/* eslint-disable indent */
export const getBusinessApplicationStatusChipColor = (
  businessApplicationStatus: BusinessApplicationStatus,
): 'error' | 'default' | 'success' | 'primary' | 'info' | 'warning' | 'secondary' => {
  switch (businessApplicationStatus) {
    case BusinessApplicationStatus.LIVE_ON_SQUID:
      return 'success';
    case BusinessApplicationStatus.APPLICATION_STARTED:
      return 'primary';
    case BusinessApplicationStatus.APPROVED:
      return 'info';
    case BusinessApplicationStatus.CHANGES_REQUIRED:
      return 'error';
    case BusinessApplicationStatus.PENDING_APPROVAL:
      return 'warning';
    default:
      return 'default';
  }
};

export const getTagOrderStatusChipColor = (
  tagOrderStatus: BusinessApplicationTagOrderStatus,
): 'error' | 'default' | 'success' | 'primary' | 'info' | 'warning' | 'secondary' => {
  switch (tagOrderStatus) {
    case BusinessApplicationTagOrderStatus.NOT_PURCHASED:
      return 'primary';
    case BusinessApplicationTagOrderStatus.PURCHASED:
      return 'info';
    case BusinessApplicationTagOrderStatus.POSTED:
      return 'info';
    case BusinessApplicationTagOrderStatus.IN_TRANSIT:
      return 'info';
    case BusinessApplicationTagOrderStatus.OUT_FOR_DELIVERY:
      return 'info';
    case BusinessApplicationTagOrderStatus.DELIVERED:
      return 'success';
    default:
      return 'default';
  }
};

export const requiredFieldErrorMessage = 'This field is required';
export const openingHoursFormatErrorMessage = 'Opening hours needs to follow the format 00:00-00:00. Use a 24h format.';
export const openingHoursPatternValidation = /^\d{2}:\d{2}-\d{2}:\d{2}$/;
